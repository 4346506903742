import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import agent from '../agent';
import defautPhoto from '../assets/default-photo.jpg';
import { API_STATIC } from "../shared/Constant";
import { cropImage } from "./CorpImage";

import "./ImageEditor.css";

const ImageEditor = observer((props) => {
    const [originalImage, setOriginalImage] = useState("");
    const [imagePath, setImagePath] = useState(defautPhoto);
    const [isImageSelected, setIsImageSelected] = useState(false);
    const [errorMesage, setErrorMessage] = useState("");

    useEffect(() => {
        if (props.imagePath) {
            setImagePath(`${API_STATIC}${props.imagePath}`);
            setOriginalImage(props.imagePath);
            setIsImageSelected(true);
        } else {
            setImagePath(defautPhoto);
            setOriginalImage("");
            setIsImageSelected(false);
        }
    }, [props.imagePath]);


    const handleFileSelect = e => {
        setErrorMessage("");
        if (e.target.files[0].size > props.maxFileSize) {
            setErrorMessage(`File size exceeds ${props.maxFileSize / 1024}KB.`);
            return;
        }
        const file = e.target.files[0];

        cropImage(file, { x: 0, y: 0, width: props.width, height: props.height }, (blob) => {
            const file = new File([blob], imagePath, {
                type: 'image/jpeg',
                lastModified: Date.now()
            });
            const imageUrl = originalImage.split('/').pop();
            agent.Image.uploadImage(file, props.folder, "")
                .then((response) => {
                    setImagePath(`${API_STATIC}${response.image}`);
                    setOriginalImage(response.image);
                    setErrorMessage("");
                    setIsImageSelected(true);
                    props.onImageChange(response.image);
                }).catch();
        });
    }

    const handleImageClick = () => {
        document.getElementById('fileInput' + props.id).click();
    };

    return (
        <div id={props.id} key={props.key} className={props.className}>
            {!isImageSelected && <div className={`image-container ${props.selectorClassName}`} onClick={handleImageClick}>
                <div className="upload-prompt">
                    <span className='icon'><i className="fas fa-plus"></i></span>
                </div>
            </div>}
            {isImageSelected && (<img src={imagePath} alt={props.alt} className={props.imageClassName} onClick={handleImageClick} />)}
            <input id={"fileInput" + props.id} type="file" onChange={handleFileSelect} />
            <div>{errorMesage}</div>
        </div>
    );
});

export default ImageEditor;