import superagentPromise from 'superagent-promise';
import _superagent from 'superagent';
import commonStore from './stores/commonStore';
import { API_ROOT } from "./shared/Constant";

const superagent = superagentPromise(_superagent, global.Promise);

const encode = encodeURIComponent;

const handleErrors = err => {
  return err;
};

const responseBody = res => res.body;

const tokenPlugin = req => {
  if (commonStore.token) {
    req.set('authorization', `Bearer ${commonStore.token}`);
  }
};

const requests = {
  del: url =>
    superagent
      .del(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      .retry(2)
      .timeout({
        response: 5000,  // Wait 5 seconds for the server to start sending,
        deadline: 60000, // but allow 1 minute for the file to finish loading.
      })
      .end(handleErrors)
      .then(responseBody),
  get: (url) =>
    superagent
      .get(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      .retry(2)
      .timeout({
        response: 50000,  // Wait 5 seconds for the server to start sending,
        deadline: 60000, // but allow 1 minute for the file to finish loading.
      })
      .end(handleErrors)
      .then(responseBody),
  put: (url, body) =>
    superagent
      .put(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .retry(2)
      .timeout({
        response: 50000,  // Wait 5 seconds for the server to start sending,
        deadline: 60000, // but allow 1 minute for the file to finish loading.
      })
      .end(handleErrors)
      .then(responseBody),
  post: (url, body) =>
    superagent
      .post(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .retry(2)
      .timeout({
        response: 30000,  // Wait 5 seconds for the server to start sending,
        deadline: 60000, // but allow 1 minute for the file to finish loading.
      })
      .end(handleErrors)
      .then(responseBody),
};

const Auth = {
  current: () =>
    requests.get(`/settings`),
  save: (userInfo) =>
    requests.post("/settings", { userInfo }),
  login: (email, code) =>
    requests.post('/login', { email, code }),
  getLoginStatus: () =>
    requests.get('/login'),
  logout: (refreshToken) =>
    requests.post('/logout', { refreshToken }),
  refreshToken: (refresh_token) =>
    requests.post('/refresh', { refresh_token }),
  validateEmail: (email) =>
    requests.post('/validate_email', { email }),
};

const Comments = {
  createComment: (comment, targetId, parentId, replyUserId, replyType, mentions) =>
    requests.post(`/comment`, { comment, targetId, parentId, replyUserId, replyType, mentions }),
  deleteComment: (commentId) =>
    requests.del(`/comment?commentId=${commentId}`),
  updateComment: (id, comment, mentions) =>
    requests.put(`/comment?content=${encode(comment)}&&commentId=${id}&&mentions=${mentions}`),
  loadComments: (targetId, commentType, page, pageSize) =>
    requests.get(`/comments?targetId=${targetId}&&type=${commentType}&&page=${page}&&pageSize=${pageSize}`)
};

const Report = {
  reportProblem: (id, type, content) =>
    requests.post(`/report_problem`, { id, type, content }),
  sendFeedback: (feedback) =>
    requests.post('/feedback', { feedback }),
}

const Post = {
  updatePost: (post, extension) =>
    requests.post("/post", { post, extension }),
  deletePost: (postId, state) =>
    requests.del(`/post?postId=${postId}&state=${state}`),
  getPost: (shortUrl, params) =>
    requests.get(`/post?shortUrl=${shortUrl}&${params}`),
  getPosts: (type, params) =>
    requests.get(`/posts/${type}${params}`),
  getPersonalPosts: (params) =>
    requests.get(`/settings/posts${params}`),
  likePost: (postId) =>
    requests.post("/like_post", { postId }),
  savePost: (postId) =>
    requests.post("/save_post", { postId }),
  ratePost: (postId, rating) =>
    requests.post("/rate_post", { postId, rating }),
  getPostRate: (postId) =>
    requests.get(`/rate_post?postId=${postId}`),
  generatePost: (tag, language) =>
    requests.get(`/generate_post/${tag}?language=${language}`),
  getAmazonAsion: (postId) =>
    requests.get(`/get_amazon_asins?postId=${postId}`),
}

const Image = {
  getImage: (image) =>
    requests.del(`/get_image?image=${image}`),
  uploadImage: (file, folder, replacedImage) =>
    superagent.post(`${API_ROOT}/upload_image`)
      .use(tokenPlugin)
      .attach("file", file)
      .field("folder", folder)
      .field("replacedImage", replacedImage)
      .end(handleErrors)
      .then(responseBody),
  deleteImage: (image) =>
    requests.del(`/upload_image?image=${image}`),
}

const Follow = {
  getFollowers: (params) =>
    requests.get(`/followers${params}`),
  getFollowings: (params) =>
    requests.get(`/followings${params}`),
  getFollow: (followee) =>
    requests.get(`/follow?followee=${followee}`),
  postFollow: (id) =>
    requests.post("/follow", { id }),
}

const Earnings = {
  getEarnings: (start, end) =>
    requests.get(`/earnings?start=${start}&end=${end}`),
  requestWithdraw: (params) =>
    requests.post("/withdraw_earnings", { params }),
  getWithdrawHistory: () =>
    requests.get("/withdraw_earnings"),
}

const Mention = {
  Suggestions: (query) =>
    requests.get(`/mention_suggestion?query=${query}`),
}

const User = {
  getUserInfo: (userName) =>
    requests.get(`/user_info?userName=${userName}`),
}

export default {
  Auth,
  Comments,
  Report,
  Post,
  Image,
  Follow,
  Earnings,
  Mention,
  User
};
