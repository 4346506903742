import React from "react";
import TopNav from "../landingPage/TopNav";
import { useTranslation } from 'react-i18next';

const NotFoundPage = (props) => {
    const { t } = useTranslation();

    return (
        <div className="landing-page">
            <TopNav isLoggedIn={false} history={props.history} />
            <h1>404</h1>
            <p>{t("PageNotFound")}</p>
        </div>
    );
};

export default NotFoundPage;
