import React, { useState, useRef } from "react";
import { observer } from "mobx-react";
import agent from '../agent';
import { cropImageHtmlElement } from "./CorpImage";
import ReactCrop, { makeAspectCrop, centerCrop } from 'react-image-crop';
import { useTranslation } from "react-i18next";
import 'react-image-crop/dist/ReactCrop.css';
import './ImageEditOverlay.css';


const ImageEditOverlay = observer((props) => {
    const intialAspect = {
        unit: '%', // Can be 'px' or '%'
        x: 25,
        y: 25,
        width: 50,
        height: 50
    };
    const [isHovering, setIsHovering] = useState(false);
    const [errorMesage, setErrorMessage] = useState("");
    const [showEditor, setShowEditor] = useState(false);
    const [imageInfo, setImageInfo] = useState(null);
    const imgRef = useRef();
    const [crop, setCrop] = useState(intialAspect);
    const [activeAspect, setActiveAspect] = useState("orignal");
    const fileInputRef = useRef();
    const { t } = useTranslation();

    const handleMouseEnter = () => {
        setIsHovering(true);
    };

    const handleMouseLeave = () => {
        setIsHovering(false);
    };

    const handleDelete = () => {
        agent.Image.deleteImage(props.imageUrl).then(
            (response) => {
                props.onDelete();
            });
    }

    const handleReplace = (e) => {
        setErrorMessage("");
        fileInputRef.current.click();
    }

    const handleImageChange = (e) => {
        if (e.target.files[0].size > props.maxFileSize) {
            setErrorMessage(`File size exceeds ${props.maxFileSize / 1024}KB.`);
            return;
        }
        const file = e.target.files[0];
        agent.Image.uploadImage(file, props.folder, "")
            .then((response) => {
                setErrorMessage("");
                setCrop(intialAspect);
                props.onImageChange(response.image);
            }).catch();
    };

    const handleEdit = () => {
        const img = new Image();
        img.src = props.imageUrl;
        img.onload = () => {
            const { width, height } = img;
            const aspectRatio = width / height;
            setImageInfo({ aspectRatio, width, height });
        };
        setShowEditor(!showEditor);
    };

    const handleEditDone = (e) => {
        setShowEditor(!showEditor);
        cropImageHtmlElement(imgRef.current, {
            x: crop.x * imageInfo.width / 100,
            y: crop.y * imageInfo.height / 100,
            width: crop.width * imageInfo.width / 100,
            height: crop.height * imageInfo.height / 100
        }, (blob) => {
            const file = new File([blob], getImageFilename(props.imageUrl), {
                type: 'image/jpeg',
                lastModified: Date.now()
            });
            agent.Image.uploadImage(file, props.folder, "")
                .then((response) => {
                    setErrorMessage("");
                    setCrop(intialAspect);
                    props.onImageChange(response.image);
                }).catch();
        }
        );
    };

    const selectAspect = (activeAspect) => {
        setActiveAspect(activeAspect)
        var newAspectRatio = imageInfo.aspectRatio;
        if (activeAspect === "orignal") {
            newAspectRatio = imageInfo.aspectRatio
        } else if (activeAspect === "mobile") {
            newAspectRatio = 3 / 4;
        } else if (activeAspect === "largescreen") {
            newAspectRatio = 10 / 6;
        }
        const newCrop = centerCrop(makeAspectCrop(
            {
                unit: '%', // Can be 'px' or '%'
                x: 25,
                y: 25,
                width: newAspectRatio > 1 ? 50 : 50 * newAspectRatio,
                height: newAspectRatio > 1 ? 50 / newAspectRatio : 50
            },
            newAspectRatio, imageInfo.width, imageInfo.height,
        ), imageInfo.width, imageInfo.height);
        setCrop(newCrop);
    }

    return (
        <div className={props.className} style={props.style} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <img className="overlay-image" src={props.imageUrl} alt="banner" />
            {isHovering && (
                <div className="overlay">
                    <button className="overlay-button" onClick={handleReplace}>
                        {t("Replace")}
                    </button>
                    <button className="overlay-button" onClick={handleEdit}>
                        {t("Edit")}
                    </button>
                    <button className="overlay-button" onClick={handleDelete}>
                        {t("Delete")}
                    </button>
                </div>
            )}
            <input type="file" accept="image/*" ref={fileInputRef} style={{ display: 'none' }} onChange={handleImageChange} />
            {showEditor &&
                <div className="popup-editor">
                    <div className="popup-editor-content">
                        <span className="close-button" onClick={handleEdit}><i className="fa fa-window-close"></i></span>
                        {imageInfo && <div className="aspect-edit-container">
                            <div className="edit-image-container">
                                <ReactCrop crop={crop} onChange={(crop, percentCrop) => setCrop(percentCrop)} aspect={getAspectRatio(imageInfo, activeAspect)} keepSelection={false}>
                                    <img ref={imgRef} src={props.imageUrl} alt="banner edit" />
                                </ReactCrop>
                            </div>
                            <div style={{ "marginLeft": "10px" }}>
                                <div className={`aspect-container ${activeAspect === "orignal" && "m-active"}`} onClick={() => selectAspect("orignal")}>
                                    <label className="aspect-label">{t("OriginalAspectRatio")}</label>
                                    <div className="aspect-frame" style={{ "height": getDimention(imageInfo.aspectRatio).height, "width": getDimention(imageInfo.aspectRatio).width }} />
                                </div>
                                <div className={`aspect-container ${activeAspect === "mobile" && "m-active"}`} onClick={() => selectAspect("mobile")}>
                                    <label className="aspect-label">{t("AspectRatio")} 3 : 4</label>
                                    <div className="aspect-frame" style={{ "height": "80px", "width": "60px" }} />
                                </div>
                                <div className={`aspect-container ${activeAspect === "largescreen" && "m-active"}`} onClick={() => selectAspect("largescreen")}>
                                    <label className="aspect-label">{t("AspectRatio")} 5 : 3</label>
                                    <div className="aspect-frame" style={{ "height": "45px", "width": "80px" }} />
                                </div>
                            </div>
                        </div>}
                        <div>{errorMesage}</div>
                        <button onClick={handleEditDone}>{t("SaveForm")}</button>
                    </div>
                </div>
            }
        </div>
    );
});

function getDimention(aspectRatio) {
    if (aspectRatio > 1) {
        return { width: "80px", height: `${80 / aspectRatio}px` }
    } else {
        return { width: `${80 * aspectRatio}px`, height: "80px" }
    }
}

function getAspectRatio(imageInfo, activeAspect) {
    if (activeAspect === "orignal") {
        return imageInfo.aspectRatio
    } else if (activeAspect === "mobile") {
        return 3 / 4;
    } else if (activeAspect === "largescreen") {
        return 10 / 6;
    }
    return 1;
}

function getImageFilename(imageUrl) {
    const url = new URL(imageUrl);
    const pathname = url.pathname;
    return pathname.split('/').pop();
}

export default ImageEditOverlay;
