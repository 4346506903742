import { makeAutoObservable } from 'mobx';
import agent from '../agent';

export class PostStore {
  posts = [];
  pageCount = 0;

  constructor() {
    makeAutoObservable(this);
  }

  getPosts(type, params, cleanStore = false) {
    if (cleanStore) {
      this.resetPosts();
    }
    return agent.Post.getPosts(type, params)
      .then((response) => {
        this.addPosts(response.posts);
        this.setPageCount(response.pageCount);
      });
  }

  resetPosts() {
    this.posts = [];
  }

  addPosts(newPosts) {
    this.posts = [...this.posts, ...newPosts];
  }

  setPageCount(count) {
    this.pageCount = count;
  }
}

export default new PostStore();
