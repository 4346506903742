import React, { useEffect, useState } from 'react';
import { inject, observer } from "mobx-react";
import TopNav from '../landingPage/TopNav';
import LoadingPage from '../shared/LoadingPage';
import PostList from "../post/PostList";
import { API_STATIC } from '../shared/Constant';
import { useTranslation } from 'react-i18next';
import agent from '../agent';

import "./Settings.css";


const UserInfo = inject("authStore")(observer((props) => {
    const [userInfo, setUserInfo] = useState(null);
    const [followerCount, setFollowerCount] = useState(0);
    const [followingCount, setFollowingCount] = useState(0);
    const [following, setFollowing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(!!props.authStore.currentUser);
    const { t } = useTranslation();
    const pathnames = props.location.pathname.split("/");
    const userId = pathnames[pathnames.length - 1];

    useEffect(() => {
        if (!props.authStore.currentUser) {
            props.authStore.getLoginStatus().then(() => {
                setIsLoggedIn(!!props.authStore.currentUser);
            })
        } else {
            setIsLoggedIn(!!props.authStore.currentUser);
        }
    }, [props.authStore.currentUser]);

    useEffect(() => {
        setIsLoading(true);
        agent.User.getUserInfo(userId).then((response) => {
            setIsLoading(false);
            setUserInfo(response.user);
        }).catch((error) => {
            setIsLoading(false);
            props.history.replace("/network-error")
        });
    }, []);

    useEffect(() => {
        if (userInfo) {
            agent.Follow.getFollow(userInfo.id).then((response) => {
                setFollowerCount(response.follower_count);
                setFollowingCount(response.following_count);
                setFollowing(response.following);
            });
        }
    }, [userInfo, props.authStore.currentUser]);

    const followHandler = e => {
        agent.Follow.postFollow(userInfo.id).then((response) => {
            setFollowerCount(response.follower_count);
            setFollowingCount(response.following_count);
            setFollowing(response.following);
        }).catch((error) => {
            if (error.status === 401) {
                props.history.replace("/login");
            }
        });
    }

    const showFollowButton = userInfo && props.authStore.currentUser === userInfo.email ? false : true;
    const buttonLabel = following ? t("Unfollow") : t("Follow");
    return (
        <div className="landing-page">
            <TopNav isLoggedIn={isLoggedIn} history={props.history} />
            {!isLoading &&
                <div>
                    {userInfo && <div className="persona-container">
                        <img src={`${API_STATIC}${userInfo.photo}`} className='persona-photo' />
                        <div style={{ "alignSelf": "center" }}>
                            <div style={{ "display": "flex" }}>
                                <div className='settings-label'>{userInfo.userName}</div>
                                {showFollowButton && <button onClick={followHandler} className='follow-button'> {buttonLabel}</button>}
                            </div>
                            <div style={{ "display": "flex" }}>
                                <div style={{ "marginRight": "20px" }}>{t("Followers")}: {followerCount}</div>
                                <div>{t("Following")}: {followingCount}</div>
                            </div>
                        </div>
                    </div>}
                    <div style={{ "marginTop": "100px", "marginBottom": "50px", "fontSize": "20px", "fontWeight": "bolder" }}>{t("Posts")}</div>
                    <PostComponent userId={userId} history={props.history} />
                </div>
            }
            {isLoading && <LoadingPage />}
        </div>);
}));

export default UserInfo;


const PostComponent = observer((props) => {
    const [posts, setPosts] = useState(null);
    const [pageCount, setPageCount] = useState(1);
    const [pageOffset, setPageOffset] = useState(0);

    useEffect(() => {
        let isMounted = true;
        agent.Post.getPosts("all", `?page=${pageOffset}&pageSize=15&state=8&userId=${props.userId}`)
            .then(response => {
                if (isMounted) {
                    setPosts(response.posts);
                    setPageCount(response.pageCount);
                }
            }).catch((error) => {
                props.history.replace("/network-error")
            });
        return () => {
            isMounted = false;
        }
    }, [pageOffset]);

    return (
        <div>
            <PostList
                posts={posts}
                history={props.history}
                pageCount={pageCount}
                onPageChange={(offset) => {
                    setPageOffset(offset);
                }}
                showState={true}
            />
        </div>
    );
});
