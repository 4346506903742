export const reportOptions =
	[
		"ThreatToPersonalSafety",
		"CheatingOrHacking",
		"ViolatingRulesGuidelines",
		"Others",
	];

export const postProblemOptions = [
	"Inappropriate",
	"Misleading",
	"Plagiarism",
	"SpamOrScam",
	"CopyrightInfringement",
	"HarmfulIngredients",
	"Violations",
	"OtherConcerns"
];

