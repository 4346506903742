import React from "react";
import TopNav from '../landingPage/TopNav';
import { inject, observer } from "mobx-react";
import LoginForm from "./LoginForm";
import "./Login.css";


const Login = inject("authStore")(observer((props) => {
	return (
		<div className="landing-page">
			<TopNav
				isLoggedIn={!!props.authStore.currentUser}
				history={props.history}
				atLoginPage={true}
			/>
			<LoginForm history={props.history} />
		</div>
	);
}));

export default Login;