import React from "react";
import { inject, observer } from "mobx-react";
import LoginForm from "./LoginForm";
import "./LoginOverlay.css";


const LoginOverlay = inject("authStore")(observer((props) => {
	return (
		<div className="login-overlay">
			<div className="popup">
				<span className="close-button" onClick={props.onClose}><i className="fa fa-window-close"></i></span>
				<LoginForm className="login-popup" onClose={props.onClose} />
			</div>
		</div>
	);
}));

export default LoginOverlay;