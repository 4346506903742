export function cropImage(file, crop, callback) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
        const image = new Image();
        image.src = event.target.result;
        image.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            canvas.width = crop.width;
            canvas.height = crop.height;
            ctx.drawImage(
                image,
                0,
                0,
                crop.width,
                crop.height
            );

            canvas.toBlob((blob) => {
                callback(blob);
            }, 'image/jpeg', 1);
        };
    };
}


export function cropImageHtmlElement(image, crop, callback) {
    const img = new Image();
    img.crossOrigin = "Anonymous";
    img.src = image.src

    img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = crop.width;
        canvas.height = crop.height;
        ctx.drawImage(
            img,
            crop.x,
            crop.y,
            crop.width,
            crop.height,
            0,
            0,
            crop.width,
            crop.height
        );

        canvas.toBlob((blob) => {
            callback(blob);
        }, 'image/jpeg', 1);
    }
}