import React from 'react';
import { useTranslation } from "react-i18next";

const OptionSelector = props => {
  const { t } = useTranslation();

  const handleChange = (e) => {
    if (props.onChange) {
      props.onChange(e);
    }
  };

  return (
    <select id={props.id} className={props.className} onChange={handleChange} value={props.value}>
      {props.options.map((item, index) => <option key={index} value={item.name}>{t(item.name)}</option>)}
    </select>
  );
}


export default OptionSelector;