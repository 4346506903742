import React, { useState } from "react";
import { Link } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { validateEmail } from "../shared/ValidateEmail";
import { parseJson } from "../shared/SafeJsonParser";
import "./Login.css";
import { useTranslation } from "react-i18next";


const LoginForm = inject("authStore")(observer((props) => {
	const [code, setCode] = useState("");
	const [email, setEmail] = useState("");
	const [errorMessage, setErrorMessage] = useState("");
	const [disabled, setDisabled] = useState(false);
	const [isCounting, setIsCounting] = useState(false);
	const [countdown, setCountdown] = useState(100);
	const { t } = useTranslation();

	const handleEmailChange = e => {
		setEmail(e.target.value);
		setErrorMessage("");
	};

	const handleCodeChange = e => {
		setCode(e.target.value);
	}

	const sendCode = e => {
		if (email === "") {
			setErrorMessage(t("EmailIsEmpty"));
			return;
		}

		if (validateEmail(email)) {
			setErrorMessage(t("CorrectEmail"));
			return;
		}

		startCountdown();
		props.authStore.validateEmail(email).then(() => {
		})
	}

	const startCountdown = () => {
		setIsCounting(true);

		setDisabled(true);
		const countdownTimer = setInterval(() => {
			setCountdown((prevCountdown) => {
				if (prevCountdown > 0) {
					return prevCountdown - 1;
				} else {
					setIsCounting(false);
					setDisabled(false);
					setCountdown(100);
					clearInterval(countdownTimer);
					return prevCountdown;
				}
			});
		}, 1000);
	};
	const handleSubmitForm = e => {
		e.preventDefault();

		if (email === "") {
			setErrorMessage(t("EmailIsEmpty"));
			return;
		}

		if (validateEmail(email)) {
			setErrorMessage(t("CorrectEmail"));
			return;
		}

		if (code === "") {
			setErrorMessage(t("ValidationEmpty"));
			return;
		}
		setErrorMessage("");
		props.authStore.login(email, code).then(() => {
			if (props.onClose) {
				props.onClose();
			}
			if (props.history) {
				props.history.replace("/");
			}
		}).catch((error) => {
			//TODO translation
			if (error.response && error.response.text) {
				setErrorMessage(parseJson(error.response.text).message);
			}
		});
	};

	const loginClassName = !!props.className ? props.className : "login-form";
	return (
		<div className={loginClassName} onSubmit={handleSubmitForm}>
			<div className="login-text">{t("SignIn")}</div>
			<input
				className="login-input"
				type="email"
				placeholder={t("Email")}
				onChange={handleEmailChange}
			/>
			<div className='validation-container'>
				<input
					className="validation-input"
					placeholder={t("ValidationCode")}
					onChange={handleCodeChange}
				/>
				<button
					className="validation-button"
					type="submit"
					onClick={sendCode}
					disabled={disabled}
				>
					{isCounting ? `${t("CodeSent")} ${countdown}s` : t("SendCode")}
				</button>
			</div>
			<div style={{ "fontSize": "small", "marginTop": "20px" }}>{t("BySigningInYourAgree")} <Link to="terms">{t("TermsOfUse")}</Link> {t("And")} <Link to="privacy">{t("PrivacyPolicy")}</Link></div>
			<button
				className="login-button"
				type="submit"
				onClick={handleSubmitForm}
			>
				{t("SignIn")}
			</button>
			<div className="error-message">{t(errorMessage)}</div>
		</div>
	);
}));

export default LoginForm;