import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import ImageEditor from "../shared/ImageEditor";
import { MAX_FILE_SIZE } from "../shared/Constant";
import { useTranslation } from "react-i18next";
import "./PostEditor.css";


const StepsEditor = observer((props) => {
    const [steps, setSteps] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        setSteps(initialSteps(props.steps));
    }, [props.steps]);

    const handleContentChange = (index, value) => {
        const newData = steps.map((obj, i) =>
            i === index ? { ...obj, content: value } : obj
        );
        setSteps(newData);
        props.onChange(newData);
    }

    const handleStepImageChange = (index, value) => {
        const newData = steps.map((obj, i) =>
            i === index ? { ...obj, image: value } : obj
        );
        setSteps(newData);
        props.onChange(newData);
    }

    const moveUp = (index) => {
        if (index > 0 && index < steps.length) {
            const newData = [...steps];
            const [removedObj] = newData.splice(index, 1);
            newData.splice(index - 1, 0, removedObj);
            setSteps(newData);
            props.onChange(newData);
        }
    }

    const moveDown = (index) => {
        if (index >= 0 && index < steps.length) {
            const newData = [...steps];
            const [removedObj] = newData.splice(index, 1);
            newData.splice(index + 1, 0, removedObj);
            setSteps(newData);
            props.onChange(newData);
        }
    }

    const deleteStep = (index) => {
        const newData = steps.filter((_obj, i) => i !== index);
        props.onChange(newData);
        setSteps(newData);
    }

    const addStep = (index, value) => {
        const newData = [...steps];
        newData.splice(index + 1, 0, value);
        setSteps(newData);
        props.onChange(newData);
    }

    const stepsComp =
        steps.map((step, index) => {
            return <div className="step" key={index}>
                <ImageEditor
                    id={"step" + index}
                    alt={"step image"}
                    className="step-image-container"
                    selectorClassName="step-image"
                    imagePath={step.image}
                    onImageChange={(image) => { handleStepImageChange(index, image) }}
                    folder={"step"}
                    width={200}
                    height={200}
                    maxFileSize={MAX_FILE_SIZE}
                />
                <textarea
                    className="step-content"
                    onChange={(e) => { handleContentChange(index, e.target.value) }}
                    value={step.content}
                    placeholder={t("Description")}
                />
                <div className="step-buttons">
                    <span className='icon' style={{ "fontSize": "25px" }} onClick={() => { moveUp(index) }}><i className="fa fa-angle-up"></i></span>
                    <span className='icon' style={{ "fontSize": "25px" }} onClick={() => { moveDown(index) }}><i className="fa fa-angle-down"></i></span>
                    <span className='icon' style={{ "fontSize": "20px" }} onClick={() => { deleteStep(index) }}><i className="fas fa-minus"></i></span>
                    <span className='icon' style={{ "fontSize": "20px" }} onClick={() => { addStep(index, { id: -1, content: "", image: "" }) }}><i className="fas fa-plus"></i></span>
                </div>
            </div>
        });

    return <div>
        <div>{t("Steps")}</div>
        {stepsComp}
    </div>;
});

function initialSteps(steps) {
    var ret = [];
    if (steps === null || steps === undefined || steps.length === 0) {
        ret.push({ id: -1, content: "", image: "" });
    } else {
        ret = steps;
    }

    return ret
}

export default StepsEditor;