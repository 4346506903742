import React, { useEffect, useState } from 'react';
import { observer } from "mobx-react";
import ReactStars from "react-rating-stars-component";
import agent from "../agent";

import "./RatingComponent.css";


const RatingComponent = observer((props) => {
    const [value, setValue] = useState(null);

    useEffect(() => {
        if (props.value) {
            setValue(props.value);
        }
    }, [props.value, props.isEditable]);

    const ratingChange = rating => {
        if (props.targetType === "post") {
            agent.Post.ratePost(props.targetId, rating)
                .then((response) => {
                    setValue(rating);
                    if (props.onChange) {
                        props.onChange(rating);
                    }
                });
        }
    };

    return <div>
        {value && <ReactStars
            count={5}
            size={24}
            activeColor="#ffd700"
            isHalf={true}
            edit={props.isEditable}
            onChange={ratingChange}
            value={value}
        />}
    </div>;

});


export default RatingComponent;
