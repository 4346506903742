import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import "./PostEditor.css";


const INITIAL_PRODUCT = { id: -1, name: "", asin: "", provider: "amazon" };
function initialProduct(products) {
    var ret = [];
    if (products === null || products === undefined || products.length === 0) {
        ret.push(INITIAL_PRODUCT);
        ret.push(INITIAL_PRODUCT);
    } else {
        ret = products;
    }

    return ret
}

const ProductsEditor = observer((props) => {
    const [products, setProducts] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        setProducts(initialProduct(props.products));
    }, [props.products]);

    const handleNameChange = (index, value) => {
        const newData = products.map((obj, i) =>
            i === index ? { ...obj, name: value } : obj
        );
        setProducts(newData);
        props.onChange(newData);
    }

    const handleAsinChange = (index, value) => {
        const newData = products.map((obj, i) =>
            i === index ? { ...obj, asin: value } : obj
        );
        setProducts(newData);
        props.onChange(newData);
    }

    const moveUp = (index) => {
        if (index > 0 && index < products.length) {
            const newData = [...products];
            const [removedObj] = newData.splice(index, 1);
            newData.splice(index - 1, 0, removedObj);
            props.onChange(newData);
            setProducts(newData);
        }
    }

    const moveDown = (index) => {
        if (index >= 0 && index < products.length) {
            const newData = [...products];
            const [removedObj] = newData.splice(index, 1);
            newData.splice(index + 1, 0, removedObj);
            props.onChange(newData);
            setProducts(newData);
        }
    }

    const deleteProduct = (index) => {
        const newData = products.filter((_obj, i) => i !== index);
        props.onChange(newData);
        setProducts(newData);
    }

    const addProduct = (index, value) => {
        const newData = [...products];
        newData.splice(index + 1, 0, value);
        props.onChange(newData);
        setProducts(newData);
    }

    const productComp = products ? products.map((product, index) => {
        return <div className="product-buttons" key={index}>
            <input
                className="product-input"
                onChange={(e) => { handleNameChange(index, e.target.value) }}
                value={product.name}
                placeholder={t("ProductName")}
            />
            <input
                className="product-input"
                onChange={(e) => { handleAsinChange(index, e.target.value) }}
                value={product.asin}
                placeholder={t("Asin")}
            />
            <span className='icon' style={{ "fontSize": "22px" }} onClick={() => { moveUp(index) }}><i className="fa fa-angle-up"></i></span>
            <span className='icon' style={{ "fontSize": "22px" }} onClick={() => { moveDown(index) }}><i className="fa fa-angle-down"></i></span>
            <span className='icon' onClick={() => { addProduct(index, INITIAL_PRODUCT) }}><i className="fas fa-plus"></i></span>
            <span className='icon' onClick={() => { deleteProduct(index) }}><i className="fas fa-minus"></i></span>
        </div >
    }) : null;

    return <div>
        <div style={{ "display": "flex" }}>
            <div style={{ "marginRight": "10px" }}>{t("Products")}</div>
            <div style={{ "fontSize": "10px", "alignSelf": "center" }}>{`${t("Provider")}: ${t("Amazon")}`}</div>
        </div>
        <div className="product">
            {productComp}
        </div>
    </div>
});

export default ProductsEditor;