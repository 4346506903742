import React, { useEffect, useState } from "react";
import TopNav from '../landingPage/TopNav';
import { observer, inject } from "mobx-react";
import LoadingPage from '../shared/LoadingPage';
import agent from '../agent';
import { useTranslation } from "react-i18next";
import { API_STATIC } from "../shared/Constant";

import "./PostGenerate.css";


const postGenerate = inject("authStore")(observer((props) => {
    const pathnames = props.location.pathname.split("/");
    const [isLoggedIn, setIsLoggedIn] = useState(!!props.authStore.currentUser);
    const [isLoading, setIsLoading] = useState(false);
    const [post, setPost] = useState(null);
    const [error, setError] = useState("");
    const [image, setImage] = useState(null);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (!props.authStore.currentUser) {
            props.authStore.getLoginStatus().then(() => {
                setIsLoggedIn(!!props.authStore.currentUser);
                if (props.authStore.loginStatusError) {
                    if (props.authStore.loginStatusError.status === 401) {
                        props.history.replace("/login")
                    }
                }
            });
        } else {
            setIsLoggedIn(!!props.authStore.currentUser);
        }
    }, [props.authStore.currentUser]);

    useEffect(() => {
        setIsLoading(true);
        setPost("");
        setImage("");
        setError("");
        agent.Post.generatePost(pathnames[pathnames.length - 1], i18n.language)
            .then(response => {
                const formattedString = response.post.replace(/\n/g, '<br>');
                setPost(formattedString);
                setImage(response.url);
                setIsLoading(false);
            }).catch((error) => {
                setIsLoading(false);
                setError(t("NoResultWithGenerating"));
            });
    }, [props.location.pathname]);

    const handleEditPost = e => {
        props.history.push(`/edit_post?title=${pathnames[pathnames.length - 1]}&description=${post}&image=${image}`);
    }

    return (
        <div className="landing-page">
            <TopNav isLoggedIn={isLoggedIn} history={props.history} />
            {!isLoading && <div style={{ "display": "flex" }} >
                {image && <img style={{ "width": "40vw", "paddingRight": "20px" }} src={`${API_STATIC}${image}`} alt="generate" />}
                {post && <div>
                    <p dangerouslySetInnerHTML={{ __html: post }}></p>
                    <div style={{ "marginTop": "20px" }}>
                        <div className="instruction">{t("RewardPointsInstruction")}</div>
                        <button className="edit-publish-button" onClick={handleEditPost}>{t("EditAndPublish")}</button>
                    </div>
                </div>}
            </div>}
            <div>{error}</div>
            {isLoading && <LoadingPage />}
        </div>
    );
}));

export default postGenerate;
