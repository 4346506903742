import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const OptionGroup = ({ options, onChange, containerStyle, type }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const { t } = useTranslation();

  const handleCheckboxChange = (index) => {
    if (type === "radio") {
      setSelectedOptions([index]);
      onChange([index]);
    } else {
      if (selectedOptions.includes(index)) {
        const newSelectedOptions = selectedOptions.filter((item) => {
          return item !== index;
        })
        setSelectedOptions(newSelectedOptions);
        onChange(newSelectedOptions);
      } else {
        const newSelectedOptions = [...selectedOptions, index];
        setSelectedOptions(newSelectedOptions);
        onChange(newSelectedOptions);
      }
    }
  };

  const inputType = type ? type : "checkbox";

  return (
    <div className={containerStyle}>
      {options.map((option, index) => (
        <label key={option}>
          <input
            id={index}
            name={type}
            style={{ "marginRight": "10px" }}
            type={inputType}
            value={index}
            checked={selectedOptions.includes(index)}
            onChange={() => handleCheckboxChange(index)}
          />
          {t(option)}
        </label>
      ))}
    </div>
  );
};

export default OptionGroup;
