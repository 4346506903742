import React from 'react';
import LandingPage from './landingPage/LandingPage';
import { Route, Switch } from 'react-router-dom';
import Login from './login/Login';
import PrivacyPage from './terms/PrivacyPolicy';
import TermsPage from './terms/TermsService';
import NetworkErrorPage from './error/ErrorPageNetwork';
import NotFoundPage from './error/ErrorPage404';
import PostEditor from "./post/PostEditor";
import PostDetailPage from "./post/PostDetailPage";
import Settings from "./user/Settings";
import UserInfo from "./user/UserInfo";
import PostSearch from "./post/PostSearch";
import PostGenerate from "./post/PostGenerate";


function App() {
  return (
    <Switch>
      <Route path='/generate_post/:tag' component={PostGenerate} />
      <Route path='/settings' component={Settings} />
      <Route path='/user_info/:userName' component={UserInfo} />
      <Route path='/posts/:type' component={PostSearch} />
      <Route path='/post/:shortUrl' component={PostDetailPage} />
      <Route path='/edit_post' component={PostEditor} />
      <Route path='/update_post/:shortUrl' component={PostEditor} />
      <Route path='/terms' component={TermsPage} />
      <Route path='/privacy' component={PrivacyPage} />
      <Route path='/login' component={Login} />
      <Route path='/network-error' component={NetworkErrorPage} />
      <Route path='/not-found' component={NotFoundPage} />
      <Route path='/' component={LandingPage} />
    </Switch>
  );
}

export default App;