import React, { useState, useEffect } from "react";
import "./NotificationBar.css";

const NotificationBar = ({ message }) => {
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(false);
        }, 4000);

        return () => {
            clearTimeout(timer);
        };
    }, []);

    return isVisible ? (
        <div className="notification-bar">
            <p>{message}</p>
        </div>
    ) : null;
};

export default NotificationBar;
