import { makeAutoObservable, reaction } from 'mobx';


class CommonStore {

  appName = 'Posts';
  token = window.localStorage.getItem('jwt');
  refreshToken = window.localStorage.getItem('refreshToken');

  constructor() {
    makeAutoObservable(this);
    reaction(
      () => this.token,
      token => {
        if (token) {
          window.localStorage.setItem('jwt', token);
        } else {
          window.localStorage.removeItem('jwt');
        }
      }
    );

    reaction(
      () => this.refreshToken,
      refreshToken => {
        if (refreshToken) {
          window.localStorage.setItem('refreshToken', refreshToken);
        } else {
          window.localStorage.removeItem('refreshToken');
        }
      }
    );
  }

  setToken(token) {
    this.token = token;
  }

  setRefreshToken(token) {
    this.refreshToken = token;
  }
}

export default new CommonStore();
