import { makeAutoObservable } from 'mobx';
import agent from '../agent';
import commonStore from './commonStore';
import { parseJson } from "../shared/SafeJsonParser";

class AuthStore {
  errorMessage = '';
  loginStatusError = null;
  currentUser = "";
  userInfo = null;

  constructor() {
    makeAutoObservable(this);
  }

  handleError(error) {
    if (error.status === 401) {
      this.errorMessage = "Email or password is wrong."
      commonStore.setToken(undefined);
      commonStore.setRefreshToken(undefined);
      this.setCurrentUser("");
    } else {
      this.errorMessage = "Unknow error. Please try again.";
      if (error.response?.text) {
        const errorJson = parseJson(error.response.text)
        if (errorJson) {
          const message = errorJson.message;
          if (message !== undefined && message !== "") {
            this.errorMessage = message;
          }
        }
      } else if (error.message) {
        this.errorMessage = error.message;
      }
    }
  }

  login(email, code) {
    this.errorMessage = "";

    return agent.Auth.login(email, code)
      .then((response) => {
        commonStore.setToken(response.access_token);
        commonStore.setRefreshToken(response.refresh_token);
        this.pullUser();
      })
  }

  validateEmail(email) {
    return agent.Auth.validateEmail(email);
  }

  logout() {
    return agent.Auth.logout(commonStore.refreshToken)
      .then(() => {
        commonStore.setToken(undefined);
        commonStore.setRefreshToken(undefined);
        this.setCurrentUser("");
      }).catch(err => {
      });
  }

  pullUser() {
    return agent.Auth.current()
      .then((response) => {
        this.setUserInfo(response);
        if (response) {
          this.setCurrentUser(response.email);
        }
      });
  }

  updateUser(userInfo) {
    return agent.Auth.save(userInfo)
      .then((response) => { this.setUserInfo(response); });
  }

  getLoginStatus() {
    this.setLoginStatusError(null);
    return agent.Auth.refreshToken(commonStore.refreshToken)
      .then((response) => {
        commonStore.setToken(response.access_token);
        this.pullUser();
      })
      .catch((error) => {
        this.setCurrentUser("");
        this.setUserInfo(null);
        commonStore.setToken("");
        this.setLoginStatusError(error);
      });
  }

  setUserInfo(userInfo) {
    this.userInfo = userInfo;
  }

  setCurrentUser(currentUser) {
    this.currentUser = currentUser;
  }

  setLoginStatusError(error) {
    this.loginStatusError = error;
  }
}

/*function encryptPassword(password) {
  const hash = CryptoJS.SHA256(password);
  return hash.toString(CryptoJS.enc.Hex);
}*/

export default new AuthStore();
