import React, { useEffect, useState } from 'react';
import { inject, observer } from "mobx-react";
import TopNav from '../landingPage/TopNav';
import PostDetail from './PostDetail';

import "./PostDetail.css";


const PostDetailPage = inject("authStore")(observer((props) => {
    const [isLoggedIn, setIsLoggedIn] = useState(!!props.authStore.currentUser);

    useEffect(() => {
        if (!props.authStore.currentUser) {
            props.authStore.getLoginStatus().then(() => {
                setIsLoggedIn(!!props.authStore.currentUser);
            })
        } else {
            setIsLoggedIn(!!props.authStore.currentUser);
        }
    }, [props.authStore.currentUser]);

    return (
        <div className="landing-page">
            <TopNav isLoggedIn={isLoggedIn} history={props.history} />
            <PostDetail location={props.location} />
        </div>
    );
}));

export default PostDetailPage;