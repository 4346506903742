import React, { useEffect, useState } from 'react';
import { inject, observer } from "mobx-react";
import agent from '../agent';
import { API_STATIC } from "../shared/Constant";
import RatingComponent from "../shared/RatingComponent";
import CommentsComponent from "../comment/CommentsComponent";
import LoginOverlay from '../login/LoginOverlay';
import { useTranslation } from 'react-i18next';
import ImageOverlay from "../shared/ImageOverlay";
import ReportProblems from "../shared/ReportProblems";
import { Link } from 'react-router-dom';

import "./PostDetail.css";


const PostDetail = inject("authStore")(observer((props) => {
    const pathnames = props.location.pathname.split("/");
    const [post, setPost] = useState(null);
    const [extension, setExtension] = useState(null);
    const [loginForm, showLogin] = useState(false);
    const [followerCount, setFollowerCount] = useState(0);
    const [followingCount, setFollowingCount] = useState(0);
    const [following, setFollowing] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        agent.Post.getPost(pathnames[pathnames.length - 1]).then((response) => {
            setPost(response.post);
            setExtension(response.extension);
        }).catch((error) => {
        });
    }, []);

    useEffect(() => {
        if (post) {
            agent.Follow.getFollow(post.authorId).then((response) => {
                setFollowerCount(response.follower_count);
                setFollowingCount(response.following_count);
                setFollowing(response.following);
            });
        }
    }, [post, props.authStore.currentUser]);

    const likeHandler = e => {
        agent.Post.likePost(post.id).then((response) => {
            setPost((prevState) => ({
                ...prevState,
                likings: response.like + post.likings
            }));
        }).catch((error) => {
            if (error.status === 401) {
                showLogin(true);
            }
        });
    }

    const saveHandler = e => {
        agent.Post.savePost(post.id).then((response) => {
            setPost((prevState) => ({
                ...prevState,
                savings: response.saving + post.savings
            }));
        }).catch((error) => {
            if (error.status === 401) {
                showLogin(true);
            }
        });
    }

    const followHandler = e => {
        agent.Follow.postFollow(post.authorId).then((response) => {
            setFollowerCount(response.follower_count);
            setFollowingCount(response.following_count);
            setFollowing(response.following);
        }).catch((error) => {
            if (error.status === 401) {
                showLogin(true);
            }
        });
    }

    if (!post) {
        return null;
    }

    const buttonLabel = following ? t("Unfollow") : t("Follow");
    const showFollowButton = props.authStore.currentUser === post.authorEmail ? false : true;
    return (
        <div className="detail-page">
            <div className='banner-detail'>
                <ImageOverlay images={post.images} title={post.title} />
            </div>
            <div className='title-container'>
                <div className='title-detail'>{post.title}</div>
                <div className='save-like-container'>
                    <span onClick={likeHandler}><i className="fa fa-heart like-icon"></i> {t("Like")}</span>
                    <span onClick={saveHandler} data-tooltip-content="Saving"><i className="fas fa-plus-circle like-icon"></i> {t("Save")}</span>
                </div>
            </div>
            <div className='follow-detail-container'>
                {post.authorPhoto && <img src={`${API_STATIC}${post.authorPhoto}`} className='user-thumbnail' />}
                <Link style={{ "marginRight": "20px" }} to={`/user_info/${post.authorUsername}`}>{post.authorUsername}</Link>
                <div style={{ "marginRight": "10px" }}>{t("Followers")}: {followerCount}</div>
                <div style={{ "marginRight": "10px" }}>{t("Following")}: {followingCount}</div>
                {showFollowButton && <button className='follow-button' onClick={followHandler}>{buttonLabel}</button>}
            </div>
            <div className='post-data-container'>
                <span style={{ "color": "#bd1f1f", "marginRight": "10px" }}><i className="fa fa-heart"></i></span>
                <div style={{ "marginRight": "20px" }}>{post.likings}</div>
                <div style={{ "marginRight": "20px" }}>{t("Views")}  {post.views}</div>
                <div style={{ "marginRight": "20px" }}>{t("Saved")} {post.savings}</div>
                <RatingComponent isEditable={false} value={post.ratings} />
                <div style={{ "marginLeft": "10px" }}>{post.ratings}</div>
            </div>
            <div dangerouslySetInnerHTML={{ __html: post.description }} />
            {post.type === "Recipe" && extension && <>
                <IngredientDetail ingredients={extension.cookIngredients} />
                <AddToAmazonCart postId={post.id} label={"Shop ingredients"} />
                <StepsDetail steps={extension.cookSteps} />
            </>}
            {post.products && post.products.length > 0 && <AddToAmazonCart postId={post.id} label={"Shop products"} />}
            <hr />
            <ReportProblems itemId={post.id} />
            <RatingComponent isEditable={true} value={post.ratings} targetId={post.id} targetType={"post"} />
            <CommentsComponent targetId={post.id} targetType={"post"} />
            {loginForm && <LoginOverlay onClose={() => { showLogin(false) }} />}
        </div>
    );
}));

const IngredientDetail = observer((props) => {
    const { t } = useTranslation();

    if (!props.ingredients) {
        return null;
    }

    return <div>
        <div className='title-catogary'>{t("Ingredients")}: </div>
        <table className='table-container'>
            <tbody>
                {Array.from(props.ingredients).map((ingredient, index) => {
                    if (index % 2 !== 0) {
                        return null;
                    }

                    const theSecondIngredient = props.ingredients.length > index + 1 ? props.ingredients[index + 1] : null;
                    return <tr key={index}>
                        <td>
                            <div style={{ "display": 'flex', "width": "100%" }}>
                                <div style={{ "width": "70%" }}>{ingredient.name}</div>
                                <div>{ingredient.weight}</div>
                            </div>
                        </td>
                        <td>
                            {theSecondIngredient && <div style={{ "display": 'flex', "width": "100%" }}>
                                <div style={{ "width": "70%" }}>{theSecondIngredient.name}</div>
                                <div>{theSecondIngredient.weight}</div>
                            </div>}
                        </td>
                    </tr>
                })}
            </tbody>
        </table>
    </div>
});

const StepsDetail = observer((props) => {
    const { t } = useTranslation();

    if (!props.steps) {
        return null;
    }

    return <div>
        <div className='title-catogary'>{t("Steps")}: </div>
        {props.steps.map((step, index) => {
            return <div key={index} style={{ "display": "flex", "marginBottom": "10px" }}>
                {step.image && <img src={`${API_STATIC}${step.image}`} className='step-image-detail' alt="step" />}
                <div>{index + 1}: {step.content}</div>
            </div>
        })}
    </div>
});

const AddToAmazonCart = observer((props) => {
    const checkoutProduct = e => {
        agent.Post.getAmazonAsion(props.postId).then((response) => {
            if (response.amazon !== "") {
                window.open(`${response.amazon}`, '_blank');
            }
            if (response.fresh !== "") {
                window.open(`https://www.amazon.com/${response.fresh}`, '_blank');
            }
        }).catch((error) => {
            if (error.status === 401) {
                //showLogin(true);
            }
        });
    }

    return (
        <button className='shop-button' onClick={checkoutProduct}>{props.label}</button>
    );
});

export default PostDetail;