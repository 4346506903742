import React, { useState } from "react";
import agent from '../agent';
import { useTranslation } from "react-i18next";
import './ImageUploader.css';


const ImageUploader = (props) => {
    const [errorMesage, setErrorMessage] = useState("");
    const { t } = useTranslation();

    const handleImageChange = (e) => {
        if (e.target.files[0].size > props.maxFileSize) {
            setErrorMessage(`File size exceeds ${props.maxFileSize / 1024}KB.`);
            return;
        }
        const file = e.target.files[0];

        agent.Image.uploadImage(file, props.folder, "")
            .then((response) => {
                setErrorMessage("");
                props.onUpload(response.image);
            }).catch();
    };

    return (
        <div className="image-upload-container">
            <label className="upload-button">
                <input type="file" accept="image/*" onChange={handleImageChange} />
                {t("AddBannerImage")}
            </label>
            <div>{errorMesage}</div>
        </div>
    );
};

export default ImageUploader;
