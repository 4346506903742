import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import TopNav from '../landingPage/TopNav';
import agent from '../agent';
import TagsInput from "../shared/TagsComp";
import StepsEditor from "./StepsEditor";
import IngredientsEditor from "./IngredientsEditor";
import LoginOverlay from "../login/LoginOverlay";
import NotificationBar from "../shared/NotificationBar";
import ImageUploader from "../shared/ImageUploader";
import ImageEditOverlay from "../shared/ImageEditOverlay";
import OptionSelector from "../shared/OptionSelector";
import ProductsEditor from "./ProductsEditor";
import { useTranslation } from "react-i18next";
import { API_STATIC } from "../shared/Constant";
import ReactQuill from 'react-quill';
import { modules, formats } from '../shared/QuillEditorConfig';

import "./PostEditor.css";
import 'react-quill/dist/quill.snow.css';

const initialPost = {
    id: -1,
    title: "",
    description: "",
    images: [],
    type: "",
    products: [],
    tags: [],
    state: 2,
};
const typeOptions = [{ name: "SelectType" }, { name: "Recipe" }, { name: "Food" }, { name: "Fashion" }, { name: "Travel" }, { name: "Pet" }, { name: "Other" }];
const PostEditor = inject("authStore")(observer((props) => {
    const [error, setError] = useState("");
    const [post, setPost] = useState(initialPost);
    const [description, setDescription] = useState("");
    const [recipe, setRecipe] = useState(null);
    const MAX_TITLE_CHARS = 200;
    const [enablePostButton, setEnablePostButton] = useState(false);
    const pathnames = props.location.pathname.split("/");
    const [notification, setNotification] = useState("");
    const [loginForm, showLogin] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(!!props.authStore.currentUser);
    const searchParams = new URLSearchParams(props.location.search);
    const { t } = useTranslation();

    useEffect(() => {
        if (!props.authStore.currentUser) {
            props.authStore.getLoginStatus().then(() => {
                setIsLoggedIn(!!props.authStore.currentUser);
                if (props.authStore.loginStatusError) {
                    if (props.authStore.loginStatusError.status === 401) {
                        props.history.replace("/login")
                    }
                }
            })
        } else {
            setIsLoggedIn(!!props.authStore.currentUser);
        }
    }, [props.authStore.currentUser]);

    useEffect(() => {
        if (pathnames[pathnames.length - 2] === "update_post") {
            agent.Post.getPost(pathnames[pathnames.length - 1], "for_edit=1").then((response) => {
                setPost({
                    ...post,
                    id: response.post.id,
                    type: response.post.type,
                    products: response.post.products,
                    tags: response.post.tags,
                    title: response.post.title,
                    images: response.post.images,
                });
                setDescription(response.post.description);
                setRecipe(response.extension);
                setEnablePostButton(true);
                setAspectRatioHeight();
            }).catch(error => { });
        } else if (searchParams && searchParams.size > 0) {
            const imageUrl = searchParams.get("image");
            const images = imageUrl && imageUrl !== "" ? [imageUrl] : [];
            setAspectRatioHeight();
            setPost({ ...post, title: searchParams.get("title"), images: images });
            setDescription(searchParams.get("description"));
        } else if (pathnames[pathnames.length - 1] === "edit_post") {
            setPost(initialPost);
            setDescription("");
        }

        setAspectRatioHeight();
        window.addEventListener('resize', setAspectRatioHeight);

        return () => {
            window.removeEventListener('resize', setAspectRatioHeight);
        };
    }, [props.location.pathname]);

    const setAspectRatioHeight = () => {
        const contentItems = document.querySelectorAll('.image-editor');
        contentItems.forEach(item => {
            const width = item.offsetWidth;
            const height = width * 0.6;
            item.style.height = height + 'px';
        });
    };

    const handleEditDone = state => {
        var errorMessage = "";

        if (!post.title) {
            errorMessage = t("InputTitleError");
        } else if (!description) {
            errorMessage = t("InputDescriptionError");
        } else if (!post.images) {
            errorMessage = t("InputImageError");
        } else if (recipe && (!recipe.cookIngredients || recipe.cookIngredients.length === 0)) {
            errorMessage = t("InputIngredientsError");
        } else if (recipe && (!recipe.cookSteps || recipe.cookSteps.length === 0)) {
            errorMessage = t("InputStepsError");
        }

        setError(errorMessage);
        if (!!errorMessage) {
            return;
        }

        post.images = post.images.map(image => {
            return image.split("?")[0]
        });

        agent.Post.updatePost({ ...post, description: description, state: state }, recipe)
            .then((response) => {
                if (state === 2) {
                    setNotification(t("SaveDraftSuccess"));
                } else if (state === 4) {
                    setNotification(t("PublishSuccess"));
                }
                props.history.push(`/post/${response.shortUrl}`);
            })
            .catch((error) => {
                if (error.status === 401) {
                    showLogin(true);
                } else {
                    if (state === 2) {
                        setNotification(t("SaveDraftFailed"));
                    } else if (state === 4) {
                        setNotification(t("PublishFailed"));
                    } else {
                        setNotification(t("UnknowError"));
                    }
                }
            });
    }

    const handleImageUpload = (value) => {
        const newData = [...post.images, value];
        updatePostField("images", newData);
        setAspectRatioHeight();
    }

    const handleImageChange = (index, value) => {
        const newData = [...post.images];
        newData[index] = value;
        updatePostField("images", newData);
        setAspectRatioHeight();
    }

    const handleImageDelete = (index) => {
        const newData = [...post.images.slice(0, index), ...post.images.slice(index + 1)];
        updatePostField("images", newData);
        setAspectRatioHeight();
    }

    const updatePostField = (field, value) => {
        setError("");
        if (field === "title") {
            const title = value.length > MAX_TITLE_CHARS ?
                value.substring(0, MAX_TITLE_CHARS) : value;
            updatePost(field, title);
            return;
        } else if (field === "type") {
            if (value === "Recipe") {
                setRecipe({
                    id: -1,
                    cookIngredients: [],
                    cookSteps: [],
                    cookTime: 15,
                    difficulty: 0
                });
            } else {
                setRecipe(null);
            }
        }
        updatePost(field, value);
    };

    const updatePost = (field, value) => {
        const newData = { ...post, [field]: value };
        setPost(newData);
        setEnablePostButton(newData.title !== "" && description !== "" && newData.images.length > 0);
    }

    const updateRecipeField = (field, value) => {
        setError("");
        setRecipe((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    };

    const handleDescriptionChange = (value) => {
        setDescription(value);
        setEnablePostButton(post.title !== "" && value !== "" && post.images.length > 0);
    }

    return (<div className="landing-page">
        <TopNav isLoggedIn={isLoggedIn} history={props.history} />
        <div className="editor-container">
            <div className="image-editor-container">
                {
                    post.images.length > 0 && post.images.map((image, index) => {
                        return <ImageEditOverlay
                            className="image-editor"
                            imageUrl={`${API_STATIC}${image}`}
                            key={index}
                            onImageChange={(value) => { handleImageChange(index, value) }}
                            onDelete={() => { handleImageDelete(index) }}
                            folder={"temp"}
                            alt={"banner image"}
                            maxFileSize={1024 * 800}
                        />
                    })
                }
                <ImageUploader
                    id={"banner"}
                    onUpload={handleImageUpload}
                    folder={"temp"}
                    maxFileSize={1024 * 800}
                />
            </div>
            <div style={{ "display": "flex" }}>
                <input
                    value={post.title ?? ""}
                    onChange={(e) => updatePostField("title", e.target.value)}
                    className="title-input"
                    placeholder={t("Title")}
                />
                <OptionSelector
                    className="type-select"
                    options={typeOptions}
                    value={post.type ?? "Other"}
                    onChange={(e) => { updatePostField("type", e.target.value) }}
                />
            </div>
            <ReactQuill
                id="question"
                className="description"
                value={description ?? ""}
                onChange={handleDescriptionChange}
                modules={modules}
                formats={formats}
            />
            <TagsInput className="tag-input" onChange={(tags) => { updatePostField("tags", tags); }} tags={post.tags} />
            {post.type !== "Recipe" && <ProductsEditor onChange={(value) => { updatePostField("products", value); }} products={post.products} />}
            {post.type === "Recipe" && recipe && < IngredientsEditor onChange={(value) => { updateRecipeField("cookIngredients", value); }} ingredients={recipe.cookIngredients} />}
            {post.type === "Recipe" && recipe && < StepsEditor onChange={(value) => { updateRecipeField("cookSteps", value); }} steps={recipe.cookSteps} />}
            <div>
                <button className="publish-button" onClick={(e) => { e.preventDefault(); handleEditDone(2); }} disabled={!enablePostButton}>{t("SaveAsDraft")}</button>
                <button className="publish-button" onClick={(e) => { e.preventDefault(); handleEditDone(4); }} disabled={!enablePostButton}>{t("Publish")}</button>
                <div>{error}</div>
                {notification !== "" && <NotificationBar message={notification} />}
            </div>
            {loginForm && <LoginOverlay onClose={() => { showLogin(false) }} />}
        </div>
    </div >);
}));

export default PostEditor;