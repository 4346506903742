import React from 'react';
import { useTranslation } from 'react-i18next';
import './LoadingPage.css';


function LoadingPage() {
    const { t } = useTranslation();

    return (
        <div className="loading-container">
            <div className="loading-text">{t("Loading")}</div>
            <div className="loading-spinner"></div>
        </div>
    );
}

export default LoadingPage;
