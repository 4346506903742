import React, { useState } from 'react';
import { observer } from "mobx-react";
import agent from '../agent';
import { postProblemOptions } from "../shared/ReportOptions";
import OptionGroup from "../shared/OptionGroup";
import { useTranslation } from 'react-i18next';
import PopupDialog from "../shared/PopupDialog"
import "./ReportProblems.css";

const ReportProblems = observer((props) => {
    const [showPopup, setShowPopup] = useState(false);
    const [options, setOptions] = useState([]);
    const [reportInput, setReportInput] = useState("");
    const { t } = useTranslation();

    const handleReportPopup = () => {
        setShowPopup(true);
    };

    const handleReportCancelClick = () => {
        setShowPopup(false);
    };

    const handleReportConfirmClick = () => {
        if (!options || options.length === 0) {
            return;
        }
        const contents = [];
        options.forEach((option) => {
            const content = option === postProblemOptions.length - 1 && reportInput !== "" ?
                `${option}_${postProblemOptions[option]}_${reportInput}` : `${option}_${postProblemOptions[option]}`;
            contents.push(content);
        })
        const reportContent = contents.join(",");
        agent.Report.reportProblem(props.itemId, "post", reportContent).then(() => {
            setShowPopup(false);
        }).catch();
    };

    const handleCheckboxChange = (options) => {
        setOptions(options)
    }

    const handleReportInputChange = (e) => {
        setReportInput(e.target.value);
    }

    const checkBox = (
        <div>
            <OptionGroup
                options={postProblemOptions}
                onChange={handleCheckboxChange}
                containerStyle="checbox-group"
            />
            <input
                id="reportOthers"
                style={{ "marginLeft": "35px", "height": "30px", "width": "80%" }}
                type="text"
                onChange={handleReportInputChange}
            />
        </div>
    );

    return (
        <div>
            <div className='report-problems' onClick={handleReportPopup}>{t("ReportProblems")}</div>
            {showPopup && (
                <PopupDialog
                    title={t("ReportProblems")}
                    message={checkBox}
                    onCancel={handleReportCancelClick}
                    onConfirm={handleReportConfirmClick}
                />)}
        </div>
    );
});

export default ReportProblems;