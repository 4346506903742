import React, { useState } from 'react';
import { observer } from "mobx-react";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import LoginOverlay from '../login/LoginOverlay';

import './SearchBar.css';


const SearchBar = observer((props) => {
    const [location, setLocation] = useState('');
    const { t } = useTranslation();
    const [loginForm, showLogin] = useState(false);

    function searchPost(e) {
        e.preventDefault();
        const searchParams = `?page=0&pageSize=15&tag=${location}`;
        props.history.push(`/posts/all${searchParams}`);
    }

    function generatePost(e) {
        e.preventDefault();
        if (!props.isLoggedIn) {
            if (!props.atLoginPage) {
                showLogin(true);
            }
        }
        const tag = location === "" ? "default" : location;
        props.history.push(`/generate_post/${tag}`);
    }

    const searchTooltip = <Tooltip>{t("SearchTooltip")}</Tooltip>;
    const generateTooltip = <Tooltip>{t("GenerateTooltip")}</Tooltip>;

    return (
        <div className='search-bar-container'>
            <input className="search-input"
                onChange={(e) => setLocation(e.target.value)}
                type="text"
                value={location}
                placeholder={t("SearchPost")} />
            <div className='button-container'>
                <OverlayTrigger placement="bottom" overlay={searchTooltip}>
                    <span onClick={searchPost} className='search-icon'><i className="fas fa-search"></i></span>
                </OverlayTrigger>
                <OverlayTrigger placement="bottom" overlay={generateTooltip}>
                    <span onClick={generatePost} className='generate-icon'><i className="fa fa-magic"></i></span>
                </OverlayTrigger>
            </div>
            {loginForm && <LoginOverlay onClose={() => { showLogin(false) }} />}
        </div>
    );
});

export default SearchBar;