import React, { useEffect, useState, useRef } from "react";
import TopNav from './TopNav';
import { observer, inject } from "mobx-react";
import PostList from "../post/PostList";
import { useTranslation } from 'react-i18next';

import './LandingPage.css';

const recipeOptions = [
  { name: "Cuisines", value: ["Chinese", "Indian", "Mexican", "Japanese"] },
  { name: "Meals", value: ["Breakfast", "Lunch", "Dinner", "Side", "Dishes", "Soups", "Drink"] },
  { name: "Ingredients", value: ["Chicken", "Beef", "Pork", "Seafood", "Fruits", "Vegetable"] },
  { name: "Baking", value: ["Bread", "Snack", "Pizza", "Cake"] }
];

const recipeDropDown = (t, onRecipeTagClick, recipeOptions, tags) => {
  return <div>
    {recipeOptions.map((catagory, index) => {
      return <div key={index} className="menu-container">
        <div className="menu-catogary">{t(catagory.name)}: </div>
        <div className="menu-item-container ">
          {catagory.value.map((tag, i) => {
            return <div key={i}
              className={`menu-item ${tags.includes(tag) && "m-active"}`}
              onClick={() => onRecipeTagClick(tag)}>
              {t(tag)}
            </div>
          })}
        </div>
      </div>
    })}
  </div>
};

const types = ["All", "Recipe", "Food", "Fashion", "Travel", "Pet", "Other"];

const LandingPage = inject("authStore", "postStore")(observer((props) => {
  const [isLoggedIn, setIsLoggedIn] = useState(!!props.authStore.currentUser);
  const [activetype, setActiveType] = useState("All");
  const [tags, setTags] = useState([]);
  const [cleanStore, setCleanStore] = useState(false);
  const [pageOffset, setPageOffset] = useState(0);
  const [refreshPost, setRefreshPost] = useState(true);
  const [showPopMenu, setShowPopupMenu] = useState(false);
  const floatingMenuRef = useRef();
  const { t } = useTranslation();
  const images = [
    '1.mp4',
    '2.mp4',
    '3.mp4',
    '4.mp4',
  ];

  useEffect(() => {
    if (!props.authStore.currentUser) {
      props.authStore.getLoginStatus().then(() => {
        setIsLoggedIn(!!props.authStore.currentUser);
      })
    } else {
      setIsLoggedIn(!!props.authStore.currentUser);
    }
  }, [props.authStore.currentUser]);

  useEffect(() => {
    if (refreshPost) {
      const tagStr = tags.length > 0 ? tags.join(",") : "";
      props.postStore.getPosts(activetype, `?page=${pageOffset}&pageSize=15&state=8&tag=${tagStr}`, cleanStore)
        .then(response => {
          setRefreshPost(false);
          setCleanStore(false);
        }).catch(error => {
          setRefreshPost(false);
          setCleanStore(false);
        });
    }
  }, [tags, activetype, pageOffset, refreshPost]);

  const handleScroll = () => {
    const currentScrollPosition = window.scrollY;
    setShowPopupMenu(false);
    if (currentScrollPosition < 150) {
      floatingMenuRef.current.style.display = 'none'; // Hide the menu
    } else {
      floatingMenuRef.current.style.display = 'block'; // Show the menu
      floatingMenuRef.current.style.top = '0'; // Show the menu
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    handleScroll();
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleTypeClick = (type) => {
    if (type != activetype) {
      setRefreshPost(true);
      setActiveType(type);
      setCleanStore(true);
      setTags([]);
    }

    if (type === "Recipe") {
      setShowPopupMenu(!showPopMenu);
    } else {
      setShowPopupMenu(false);
    }
  }

  const onRecipeTagClick = (tag) => {
    setTags([tag]);
    setRefreshPost(true);
    setShowPopupMenu(false);
    setCleanStore(true);
  }

  const loadMore = () => {
    setPageOffset(pageOffset + 1);
    setRefreshPost(true);
  }

  const typeSelector = types.map((type, index) => {
    return <div
      key={index}
      className={`type-item ${activetype === type && "m-active"}`}
      onClick={() => handleTypeClick(type)}
    >
      {t(type)}
    </div>
  })

  return (
    <div>
      <div className="landing-page">
        <TopNav isLoggedIn={isLoggedIn} history={props.history} />
        <div>
          <div className="type-selector">
            {typeSelector}
          </div>
          {activetype === "Recipe" && showPopMenu && (
            <div className="menu-dropdown">
              {recipeDropDown(t, onRecipeTagClick, recipeOptions, tags)}
            </div>
          )}
        </div>
        <div className="floating-menu" ref={floatingMenuRef}>
          <div className="type-selector">
            {typeSelector}
          </div>
          {activetype === "Recipe" && showPopMenu && (
            <div className="menu-dropdown">
              {recipeDropDown(t, onRecipeTagClick, recipeOptions, tags)}
            </div>
          )}
        </div>
        <div className="image-wall-container">
          <div className="image-wall primary">
            {images.map((imageUrl, index) => (
              <video key={index} autoPlay loop muted playsInline preload="auto" className="landing-banner-image">
                <source src={imageUrl} type="video/mp4" />
              </video>

            ))}
          </div>
          <div className="image-wall secondary">
            {images.map((imageUrl, index) => (
              <video key={index} autoPlay loop muted playsInline preload="auto" className="landing-banner-image">
                <source src={imageUrl} type="video/mp4" />
              </video>
            ))}
          </div>
        </div>
        <div className="image-overlay-text ">{t("BannerText")}</div>
        <div className="type-selector">{[t(activetype), ...tags.map(tag => { return t(tag) })].join(" | ")}</div>
        <div style={{ "marginBottom": "20px" }}>
          <PostList
            posts={props.postStore.posts}
            pageCount={props.postStore.pageCount}
            loadMore={loadMore}
            disablePagination={true}
          />
        </div>
        <div className="footer-container">
          <div>{t("ContactUs")}</div>
        </div>
      </div>
    </div>
  );
}));


export default LandingPage;
