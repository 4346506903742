import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import OptionSelector from "../shared/OptionSelector";
import { useTranslation } from "react-i18next";

import "./PostEditor.css";


const INITIAL_INGREDIENT = { id: -1, name: "", weight: "", asin: "", provider: "" };
const providerOptions = [{ name: "Amzon" }, { name: "Fresh" }];

function initialIngredient(ingredients) {
    var ret = [];
    if (ingredients === null || ingredients === undefined || ingredients.length === 0) {

        ret.push(INITIAL_INGREDIENT);
        ret.push(INITIAL_INGREDIENT);
    } else {
        ret = ingredients;
    }

    return ret
}

const IngredientsEditor = observer((props) => {
    const [ingredients, setIngredients] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        setIngredients(initialIngredient(props.ingredients));
    }, [props.ingredients]);

    const handleNameChange = (index, value) => {
        const newData = ingredients.map((obj, i) =>
            i === index ? { ...obj, name: value } : obj
        );
        setIngredients(newData);
        props.onChange(newData);
    }

    const handleWeightChange = (index, value) => {
        const newData = ingredients.map((obj, i) =>
            i === index ? { ...obj, weight: value } : obj
        );
        setIngredients(newData);
        props.onChange(newData);
    }

    const handleAsinChange = (index, value) => {
        const newData = ingredients.map((obj, i) =>
            i === index ? { ...obj, asin: value } : obj
        );
        setIngredients(newData);
        props.onChange(newData);
    }

    const handleProviderChange = (index, value) => {
        const newData = ingredients.map((obj, i) =>
            i === index ? { ...obj, provider: value } : obj
        );
        setIngredients(newData);
        props.onChange(newData);
    }

    const moveUp = (index) => {
        if (index > 0 && index < ingredients.length) {
            const newData = [...ingredients];
            const [removedObj] = newData.splice(index, 1);
            newData.splice(index - 1, 0, removedObj);
            props.onChange(newData);
            setIngredients(newData);
        }
    }

    const moveDown = (index) => {
        if (index >= 0 && index < ingredients.length) {
            const newData = [...ingredients];
            const [removedObj] = newData.splice(index, 1);
            newData.splice(index + 1, 0, removedObj);
            props.onChange(newData);
            setIngredients(newData);
        }
    }

    const deleteIngredient = (index) => {
        const newData = ingredients.filter((_obj, i) => i !== index);
        props.onChange(newData);
        setIngredients(newData);
    }

    const addIngredient = (index, value) => {
        const newData = [...ingredients];
        newData.splice(index + 1, 0, value);
        props.onChange(newData);
        setIngredients(newData);
    }


    const ingredientComp = ingredients ? ingredients.map((ingredient, index) => {
        return <div className="product-buttons" key={index}>
            <input
                className="ingredient-input"
                onChange={(e) => { handleNameChange(index, e.target.value) }}
                value={ingredient.name}
                placeholder={t("IngredientName")}
            />
            <input
                className="weight-input"
                onChange={(e) => { handleWeightChange(index, e.target.value) }}
                value={ingredient.weight}
                placeholder={t("Weight")}
            />
            <input
                className="ingredient-input"
                onChange={(e) => { handleAsinChange(index, e.target.value) }}
                value={ingredient.asin}
                placeholder={t("Asin")}
            />
            <OptionSelector
                className="provider-select"
                options={providerOptions}
                value={ingredient.provider}
                onChange={(e) => { handleProviderChange(index, e.target.value) }}
            />
            <span className='icon' style={{ "fontSize": "22px" }} onClick={() => { moveUp(index) }}><i className="fa fa-angle-up"></i></span>
            <span className='icon' style={{ "fontSize": "22px" }} onClick={() => { moveDown(index) }}><i className="fa fa-angle-down"></i></span>
            <span className='icon' onClick={() => { addIngredient(index, INITIAL_INGREDIENT) }}><i className="fas fa-plus"></i></span>
            <span className='icon' onClick={() => { deleteIngredient(index) }}><i className="fas fa-minus"></i></span>
        </div >
    }) : null;

    return <div>
        <div>{t("Ingredients")}</div>
        <div className="product">
            {ingredientComp}
        </div>
    </div>
});

export default IngredientsEditor;