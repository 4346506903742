import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'bulma/css/bulma.css';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from "mobx-react";

//import commentsStore from "./stores/commentsStore";
import authStore from "./stores/authStore";
import commonStore from "./stores/commonStore";
import commentsStore from "./stores/commentsStore";
import postStore from "./stores/postStore";
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18next';
import enTranslation from './locales/en.json';
import cnTranslation from './locales/cn.json';
import { language } from "./shared/Constant";

const stores = {
  authStore,
  commonStore,
  commentsStore,
  postStore
};

i18n.init({
  interpolation: { escapeValue: false }, // React already escapes strings
  lng: language, // Set the default language
  fallbackLng: 'en',
  resources: {
    en: { translation: enTranslation },
    cn: { translation: cnTranslation }
  }
});

const i18nLanguage = i18n.language;
document.getElementById('app-title').innerText = i18n.t('appTitle');

const appIconLink = document.getElementById('app-icon');
appIconLink.href = `/${i18nLanguage === 'cn' ? 'y.jpg' : 'l.jpg'}`;

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Provider {...stores}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </I18nextProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
