import React, { useEffect, useState } from "react";
import TopNav from '../landingPage/TopNav';
import { observer, inject } from "mobx-react";
import PostList from "./PostList";
import agent from '../agent';
import { useTranslation } from "react-i18next";


const PostSearch = inject("authStore")(observer((props) => {
    const [isLoggedIn, setIsLoggedIn] = useState(!!props.authStore.currentUser);
    const [posts, setPosts] = useState(null);
    const [pageCount, setPageCount] = useState(1);
    const [found, setFound] = useState(true);
    const [searchParams, setSearchParams] = useState(null);
    const [pathnames, setPathnames] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {
        if (!props.authStore.currentUser) {
            props.authStore.getLoginStatus().then(() => {
                setIsLoggedIn(!!props.authStore.currentUser);
            })
        } else {
            setIsLoggedIn(!!props.authStore.currentUser);
        }
    }, [props.authStore.currentUser]);

    useEffect(() => {
        const searchParams = new URLSearchParams(props.location.search);
        const pathnames = props.location.pathname.split("/");

        agent.Post.getPosts(pathnames[pathnames.length - 1], props.location.search)
            .then(response => {
                setPosts(response.posts);
                setPageCount(response.pageCount);
                setFound(response.found);
                setSearchParams(searchParams);
                setPathnames(pathnames);
            });
    }, [props.location]);

    const handlePageChange = (offset) => {
        searchParams.set("page", offset)
        props.history.push(`/posts/${pathnames[pathnames.length - 1]}?${searchParams.toString()}`)
    }

    const tag = searchParams ? searchParams.get("tag") : "";
    const searchPageTitle = found ? tag : t('SearchPageTitle', { tag });
    return (
        <div className="landing-page">
            <TopNav isLoggedIn={isLoggedIn} history={props.history} />
            <div>
                <div className="post-catagory">{searchPageTitle}</div>
                <PostList posts={posts} pageCount={pageCount} onPageChange={handlePageChange} />
            </div>
        </div>
    );
}));

export default PostSearch;
