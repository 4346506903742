import { makeAutoObservable } from 'mobx';
import agent from '../agent';

export class CommentsStore {
  commentErrors = undefined;
  comments = {};

  constructor() {
    makeAutoObservable(this);
  }

  loadComments(targetId, commentType, pageOffset, pageSize) {
    return agent.Comments.loadComments(targetId, commentType, pageOffset, pageSize)
      .then((response) => {
        this.addComments(targetId, response.comment, pageOffset);
      });
  }

  createComment(comment, targetId, commentType, parentId, replyUserId, replyType, mentions) {
    this.isCreatingComment = true;
    return agent.Comments.createComment(comment, targetId, parentId, replyUserId, replyType, mentions)
      .then(() => {
        this.loadComments(targetId, commentType, 0, 10)
      });
  }

  deleteComment(targetId, commentType, id) {
    return agent.Comments.deleteComment(id)
      .then(() => {
        this.loadComments(targetId, commentType, 0, 10);
      });
  }

  updateComment(targetId, commentType, commentId, comment, mentions) {
    return agent.Comments.updateComment(commentId, comment, mentions)
      .then(() => {
        this.loadComments(targetId, commentType, 0, 10);
      });
  }

  reportComment(commentId, reportContent) {
    return agent.Report.reportProblem(`comment_${commentId}`, reportContent);
  }

  addComments(targetId, comments, pageOffset) {
    if (this.comments[targetId] && pageOffset !== 0) {
      this.comments[targetId] = [...this.comments[targetId], ...comments];
    } else {
      this.comments[targetId] = comments;
    }
  }

  clearComments(targetId,) {
    this.comments[targetId] = [];
  }
}

export default new CommentsStore();
