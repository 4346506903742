import React, { useEffect, useState } from 'react';
import { inject, observer } from "mobx-react";
import TopNav from '../landingPage/TopNav';
import ImageEditor from '../shared/ImageEditor';
import LoadingPage from '../shared/LoadingPage';
import PostList from "../post/PostList";
import { API_STATIC } from '../shared/Constant';
import { useTranslation } from 'react-i18next';
import LoginOverlay from '../login/LoginOverlay';
import { MAX_FILE_SIZE } from "../shared/Constant";
import agent from '../agent';

import "./Settings.css";


const Settings = inject("authStore")(observer((props) => {
	const [isActive, setIsActive] = useState("Personal");
	const [isLoading, setIsLoading] = useState(false);
	const [isLoggedIn, setIsLoggedIn] = useState(!!props.authStore.currentUser);
	const { t } = useTranslation();

	useEffect(() => {
		if (!props.authStore.currentUser) {
			props.authStore.getLoginStatus().then(() => {
				setIsLoggedIn(!!props.authStore.currentUser);
				if (props.authStore.loginStatusError) {
					if (props.authStore.loginStatusError.status === 401) {
						props.history.replace("/login")
					}
				}
			});
		} else {
			setIsLoggedIn(!!props.authStore.currentUser);
		}
	}, [props.authStore.currentUser]);

	useEffect(() => {
		if (!props.authStore.userInfo) {
			setIsLoading(true);
			props.authStore.pullUser().then(() => {
				setIsLoading(false);
			}).catch((error) => {
				setIsLoading(false);
				if (error.status === 401) {
					props.history.replace("/login")
				} else {
					props.history.replace("/network-error")
				}
			});
		}
	}, [props.authStore.userInfo]);


	const itemOnClick = (key) => {
		setIsActive(key);
	};

	const handlePhotoSubmit = (file) => {
		updateUserInfo({ ...props.authStore.userInfo, photo: file });
	};

	const updateUserInfo = (userInfo) => {
		props.authStore.updateUser(userInfo).then(() => {
		}).catch((error) => {
			if (error.status === 401) {
				props.history.replace("/login")
			} else {
				props.history.replace("/network-error")
			}
		});
	}

	const LeftPaneNav = (<div className='setting-nav'>
		<div
			key={"Personal"}
			className={`settings-left-nav ${isActive === "Personal" && "m-active"}`}
			onClick={() => itemOnClick("Personal")}
		>
			{t("MyProfile")}
		</div>
		<div
			key={"Published"}
			className={`settings-left-nav ${isActive === "Published" && "m-active"}`}
			onClick={() => itemOnClick("Published")}
		>
			{t("MyPublish")}
		</div>
		<div
			key={"Draft"}
			className={`settings-left-nav ${isActive === "Draft" && "m-active"}`}
			onClick={() => itemOnClick("Draft")}
		>
			{t("MyDraft")}
		</div>
		<div
			key={"Saved"}
			className={`settings-left-nav ${isActive === "Saved" && "m-active"}`}
			onClick={() => itemOnClick("Saved")}
		>
			{t("MySaved")}
		</div>
		<div
			key={"Follower"}
			className={`settings-left-nav ${isActive === "Follower" && "m-active"}`}
			onClick={() => itemOnClick("Follower")}
		>
			{t("MyFollowing")}
		</div>
		<div
			key={"Following"}
			className={`settings-left-nav ${isActive === "Following" && "m-active"}`}
			onClick={() => itemOnClick("Following")}
		>
			{t("MyFollowers")}
		</div>
		<div
			key={"Earnings"}
			className={`settings-left-nav ${isActive === "Earnings" && "m-active"}`}
			onClick={() => itemOnClick("Earnings")}
		>
			{t("MyEarnings")}
		</div>
	</div>);

	if (!props.authStore.userInfo) {
		return null;
	}
	return (
		<div className="landing-page">
			<TopNav isLoggedIn={isLoggedIn} history={props.history} />
			{!isLoading && <>
				<div style={{ "marginBottom": "20px", "display": "flex" }}>
					<ImageEditor
						alt={"persona"}
						selectorClassName="persona-selector"
						imageClassName="persona-image"
						imagePath={props.authStore.userInfo.photo}
						onImageChange={handlePhotoSubmit}
						folder={"persona"}
						width={200}
						height={200}
						maxFileSize={MAX_FILE_SIZE}
					/>
					{props.authStore.userInfo && <div style={{ "alignSelf": "flex-end" }}>{t("RewardPoints")}: {props.authStore.userInfo.score}</div>}
				</div>
				<div>
					{LeftPaneNav}
					<div>
						{isActive === "Personal" && props.authStore.userInfo &&
							<PersonalComponent
								userInfo={props.authStore.userInfo}
								history={props.history}
								onChange={updateUserInfo}
							/>}
						{isActive === "Published" && <PostComponent state={12} published={true} history={props.history} />}
						{isActive === "Draft" && <PostComponent state={2} draft={true} history={props.history} />}
						{isActive === "Saved" && <PostComponent state={8} savedPost={true} history={props.history} />}
						{isActive === "Follower" && <FollowerComponent userId={props.authStore.userInfo.id} history={props.history} />}
						{isActive === "Following" && <FollowingComponent userId={props.authStore.userInfo.id} history={props.history} />}
						{isActive === "Earnings" && <EarningsComponent userId={props.authStore.userInfo.id} history={props.history} />}
					</div>
				</div></>}
			{isLoading && <LoadingPage />}
		</div>);
}));

export default Settings;

const PersonalComponent = observer((props) => {
	const [userInfo, setUserInfo] = useState(props.userInfo);
	const [disableSave, setDisableSave] = useState(true);
	const { t } = useTranslation();

	useEffect(() => {
		setUserInfo(props.userInfo);
	}, [props.userInfo]);

	const handleSave = e => {
		props.onChange(userInfo);
	};

	const handleFieldChange = (fieldName, value) => {
		setUserInfo((prevState) => ({
			...prevState,
			[fieldName]: value
		}));
		setDisableSave(false);
	};

	return (
		<div className="setting-personal-container">
			<div className="settings-item-container">
				<div className='settings-label'>{t("Username")}: </div>
				<input
					id="username"
					value={userInfo.userName ?? ""}
					onChange={(e) => handleFieldChange("userName", e.target.value)}
					className='settings-input '
				/>
			</div>
			<div className="settings-item-container">
				<label className="settings-label">{t("Phone")}: </label>
				{!props.userInfo.phone && <input
					id="phone"
					value={userInfo.phone ?? ""}
					onChange={(e) => handleFieldChange("phone", e.target.value)}
					className='settings-input '
				/>}
				{!!props.userInfo.phone && <div>{userInfo.phone}</div>}
			</div>
			<div className="settings-item-container">
				<label className="settings-label">{t("Email")}: </label>
				{!props.userInfo.email && <input
					id="email"
					value={userInfo.email ?? ""}
					onChange={(e) => handleFieldChange("email", e.target.value)}
					className='settings-input '
				/>}
				{!!props.userInfo.email && <div>{userInfo.email}</div>}
			</div>
			<div className="settings-item-container">
				<div className='settings-label'>{t("Name")}: </div>
				<input
					id="name"
					value={userInfo.name ?? ""}
					onChange={(e) => handleFieldChange("name", e.target.value)}
					className='settings-input '
				/>
			</div>
			<div className="settings-item-container">
				<label className="settings-label">{t("Address")}: </label>
				<input
					id="address"
					value={userInfo.address ?? ""}
					onChange={(e) => handleFieldChange("address", e.target.value)}
					className='settings-input '
				/>
			</div>
			<div className="settings-button-container">
				<button className="settings-button" onClick={handleSave} disabled={disableSave}>{t("SaveForm")}</button>
			</div>
		</div>
	);
});


const PostComponent = observer((props) => {
	const [posts, setPosts] = useState(null);
	const [pageCount, setPageCount] = useState(1);
	const [pageOffset, setPageOffset] = useState(0);
	const [refreshPost, setRefreshPost] = useState(true);

	useEffect(() => {
		if (refreshPost) {
			const params = props.savedPost ? "&saved=1" : "";
			agent.Post.getPersonalPosts(`?page=${pageOffset}&pageSize=15&state=${props.state}` + params)
				.then(response => {
					setPosts(response.posts);
					setPageCount(response.pageCount);
					setRefreshPost(false);
				}).catch((error) => {
					setRefreshPost(false);
					if (error.status === 401) {
						props.history.replace("/login")
					} else {
						props.history.replace("/network-error")
					}
				});
		}
	}, [refreshPost, pageOffset, props.savedPost]);

	return (
		<div>
			<PostList
				posts={posts}
				draft={props.draft}
				savedPost={props.savedPost}
				published={props.published}
				history={props.history}
				pageCount={pageCount}
				onChange={() => setRefreshPost(true)}
				onPageChange={(offset) => {
					setPageOffset(offset);
					setRefreshPost(true);
				}}
				showState={true}
			/>
		</div>
	);
});

const FollowerComponent = observer((props) => {
	const [followers, setFollowers] = useState([]);
	const [loginForm, showLogin] = useState(false);
	const { t } = useTranslation();

	useEffect(() => {
		agent.Follow.getFollowers("?page=0&pageSize=16")
			.then(response => {
				setFollowers(response.followers);
			}).catch((error) => {
				if (error.status === 401) {
					props.history.replace("/login")
				} else {
					props.history.replace("/network-error")
				}
			});
	}, []);

	const unfollowHanlder = id => {
		agent.Follow.postFollow(id).then((response) => {
			agent.Follow.getFollowers("?page=0&pageSize=16")
				.then(response => {
					setFollowers(response.followers);
				});
		}).catch((error) => {
			if (error.status === 401) {
				showLogin(true);
			}
		});
	}

	return (<div>
		{followers.map((follower, index) => {
			return <div className='follow-container' key={index}>
				<img alt={"person"} className='follow-image' src={`${API_STATIC}${follower.photo}`} />
				<div style={{ "marginRight": "10px" }}>{follower.userName} </div>
				<button style={{ "marginRight": "10px" }} onClick={() => { unfollowHanlder(follower.id) }}> {t("Unfollow")}</button>
			</div >
		})}
		{loginForm && <LoginOverlay onClose={() => { showLogin(false) }} />}
	</div >);
});

const FollowingComponent = observer((props) => {
	const [followings, setFollowings] = useState([]);

	useEffect(() => {
		agent.Follow.getFollowings("?&page=0&pageSize=16")
			.then(response => {
				setFollowings(response.followings);
			}).catch((error) => {
				if (error.status === 401) {
					props.history.replace("/login")
				} else {
					props.history.replace("/network-error")
				}
			});
	}, []);

	return (<div>
		{followings.map((followering, index) => {
			return <div className='follow-container' key={index}>
				<img alt={"person"} className='follow-image' src={`${API_STATIC}${followering.photo}`} />
				<div>{followering.userName} </div>
			</div>
		})}
	</div>);
});

const EarningsComponent = observer((props) => {
	const [totalEarnings, setTotalEarnings] = useState(0);
	const [currentWeekEarnings, setCurrentWeekEarnings] = useState(0);
	const [balance, setBalance] = useState(0);
	const [name, setName] = useState("");
	const [address, setAddress] = useState("");
	const [withDraws, setWithDraws] = useState([]);
	const [amount, setAmount] = useState(0);
	const [earnings, setEarnings] = useState([]);
	const [expandEarnings, setExpandEarnings] = useState(false);
	const [expandWithdraws, setExpandWithdraws] = useState(false);
	const [earningsError, setEarningsError] = useState("");
	const [withdrawsError, setWithdrawsError] = useState("");
	const { t } = useTranslation();

	useEffect(() => {
		agent.Earnings.getEarnings(-1, -1)
			.then(response => {
				setTotalEarnings(response.total);
				setCurrentWeekEarnings(response.current);
				setBalance(response.balance);
			}).catch((error) => {
				if (error.status === 401) {
					props.history.replace("/login")
				} else {
					props.history.replace("/network-error")
				}
			});
	}, []);

	const getEarningsHistory = e => {
		if (!expandEarnings) {
			agent.Earnings.getEarnings(0, 0).then(response => {
				setEarnings(response.history);
			}).catch((error) => {
				setEarningsError(t("ErrorFailedToGetEarningsHistory"))
			});
		}
		setExpandEarnings(!expandEarnings);
	}

	const requestWithdraw = e => {
		agent.Earnings.requestWithdraw({
			name: name,
			address: address,
			amount: amount
		}).then(response => {
		}).catch((error) => {
			if (error.status === 401) {
				props.history.replace("/login")
			} else {
				setWithdrawsError(t(error));
			}
		});
	}

	const getWithdrawHistory = e => {
		if (!expandWithdraws) {
			agent.Earnings.getWithdrawHistory().then(response => {
				setWithDraws(response.history);
			}).catch((error) => {
				setWithdrawsError(t("ErrorFailedToGetWithDrawHistory"));
			});
		}
		setExpandWithdraws(!expandWithdraws)
	}

	const withDrawsComp = withDraws.length > 0 ? withDraws.map(withDraw => {
		return <div className='row-container' style={{ "marginBottom": "10px" }}>
			<div style={{ "marginRight": "10px" }}>{withDraw.actionTime}</div>
			<div style={{ "marginRight": "10px" }}>{t(getWithdrawStatus(withDraw.state))}</div>
			<div>{withDraw.name} {t("withdraw")} {withDraw.amount}, {t("ACheckSentToAddress")} {withDraw.address}</div>
		</div>
	}) : <div>{t("NoHistory")}</div>;

	const earningsComp = earnings.length > 0 ? earnings.map(earning => {
		return <div className='row-container'>
			<div>{earning.weekNumber}</div>
			<div>{earning.amount}</div>
		</div>
	}) : <div>{t("NoHistory")}</div>;

	return (<div>
		<div className='earnings-container'>
			<div className='earnings-item'>{t("Balance")}: ${balance}</div>
			<div className='earnings-item'>{t("TotalEarnings")}: ${totalEarnings}</div>
			<div className='earnings-item'>{t("CurrentWeekEarnings")}: ${currentWeekEarnings}</div>
		</div>
		<hr />
		<div className='earnings-history'>
			<div className='row-container' onClick={getEarningsHistory}>
				<div style={{ "fontSize": "20px", "marginBottom": "10px" }}>{t("EarningsHistory")}</div>
				{!expandEarnings && <span className='icon' style={{ "paddingTop": "10px" }}><i className="fa fa-angle-down"></i></span>}
				{expandEarnings && <span className='icon' style={{ "paddingTop": "10px" }}><i className="fa fa-angle-up"></i></span>}
			</div>
			{
				expandEarnings && <div>
					{earningsComp}
					{earningsError && <div>{earningsError}</div>}
				</div>
			}
		</div>
		<hr />
		<div className='withdraw-history'>
			<div className='row-container' onClick={getWithdrawHistory}>
				<div style={{ "fontSize": "20px", "marginBottom": "10px" }}>{t("Withdraw")}</div>
				{!expandWithdraws && <span className='icon' style={{ "paddingTop": "10px" }}><i className="fa fa-angle-down"></i></span>}
				{expandWithdraws && <span className='icon' style={{ "paddingTop": "10px" }}><i className="fa fa-angle-up"></i></span>}
			</div>
			{
				expandWithdraws && <div>
					{withDrawsComp}
					{withdrawsError && <div>{withdrawsError}</div>}
					<div className='settings-item-container' style={{ "marginTop": "20px" }}>
						<div className='settings-label'>{t("Name")}:</div>
						<input className='settings-input' onChange={(e) => { setName(e.target.value) }} />
					</div>
					<div className='settings-item-container'>
						<div className='settings-label'>{t("Address")}:</div>
						<input className='settings-input' onChange={(e) => { setAddress(e.target.value) }} />
					</div>
					<div className='settings-item-container'>
						<div className='settings-label'>{t("Amount")}:</div>
						<input className='settings-input' onChange={(e) => { setAmount(e.target.value) }} />
					</div>
					<button className="settings-button" onClick={requestWithdraw}>{t("RequestWithdraw")}</button>
					<div style={{ "fontSize": "10px", "marginTop": "10px" }}>{t("NoteForWithdraw")}</div>
				</div>
			}
		</div>
	</div>);
});

function getWithdrawStatus(state) {
	if (state === 0) {
		return "Submitted";
	} else if (state === 1) {
		return "Reviewing";
	}
	else if (state === 2) {
		return "WithdrawSent";
	}
	else if (state === 3) {
		return "Complete";
	} else {
		return "";
	}
}