import React, { useEffect, useState, useRef } from "react";
import { inject, observer } from "mobx-react";
import PopupDialog from "../shared/PopupDialog"
import OptionGroup from "../shared/OptionGroup";
import { reportOptions } from "../shared/ReportOptions";
import { parseJson } from "../shared/SafeJsonParser";
import { timeAgo } from "../shared/TimeConverter";
import LoginOverlay from '../login/LoginOverlay';
import { useTranslation } from "react-i18next";
import { Mention, MentionsInput } from "react-mentions";
import { Link } from "react-router-dom";
import classNames from './MentionStyle.module.css'

import agent from '../agent';
import "./CommentsComponent.css";


const CommentComponent = inject("commentsStore")(observer((props) => {
    const [isReplying, setIsReplying] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [isCommentMenuVisible, setIsCommentMenuVisible] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [options, setOptions] = useState([]);
    const [reportInput, setReportInput] = useState("");
    const ref = useRef();
    const dropDownRef = useRef();
    const { t, i18n } = useTranslation();

    useEffect(() => {
        function handleOutsideClick(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setIsReplying(false);
            }

            if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
                setIsCommentMenuVisible(false);
            }
        }

        document.addEventListener("click", handleOutsideClick, { capture: true });
        return () => document.removeEventListener("click", handleOutsideClick);
    }, [ref, dropDownRef]);

    const showPopUpForComment = e => {
        setIsCommentMenuVisible(!isCommentMenuVisible);
    };

    const replyComment = e => {
        setIsReplying(!isReplying);
    };

    const replyCommentDone = e => {
        setIsReplying(false);
    };

    const updateCommentDone = e => {
        setIsUpdating(false);
    }

    const editComment = e => {
        setIsCommentMenuVisible(false);
        setIsUpdating(true);
    }

    const deleteComment = e => {
        props.commentsStore.deleteComment(props.targetId, props.targetType, props.comment.id);
        setIsCommentMenuVisible(false);
    }

    const reportComment = e => {
        setIsCommentMenuVisible(false);
        setShowPopup(true);
    }

    const handleReportCancelClick = () => {
        setShowPopup(false);
    };

    const handleReportConfirmClick = () => {
        if (!options || options.length === 0) {
            return;
        }
        const contents = [];
        options.forEach((option) => {
            const content = option === reportOptions.length - 1 && reportInput !== "" ?
                `${option}_${reportOptions[option]}_${reportInput}` : `${option}_${reportOptions[option]}`;
            contents.push(content);
        })
        const reportContent = contents.join(",");
        props.commentsStore.reportComment(props.comment.id, "comment", reportContent).then(() => {
            setShowPopup(false);
        }).catch();
    };

    const handleCheckboxChange = (options) => {
        setOptions(options)
    }

    const handleReportInputChange = (e) => {
        setReportInput(e.target.value);
    }

    const checkBox = (
        <div>
            <OptionGroup
                options={reportOptions}
                onChange={handleCheckboxChange}
                containerStyle="checbox-group"
            />
            <input
                id="reportOthers"
                style={{ "marginLeft": "35px", "height": "30px", "width": "80%" }}
                type="text"
                onChange={handleReportInputChange}
            />
        </div>
    );

    const parentId = props.comment.parentId.includes("comment") ? props.comment.parentId.split("_")[1] : props.comment.id;
    const childrenComments = parseJson(props.comment.children);
    const creationTime = timeAgo(Date.parse(props.comment.creation_time), i18n.language); //`${props.comment.creation_time?.split(".")[0]}`
    return (
        <div>
            <li
                ref={ref}
                key={props.index}
                className="comment-item"
            >
                <div className="comment-container">
                    <div style={{ "display": "flex" }}>
                        <Link to={`/user_info/${props.comment.author}`}>{props.comment.author}</Link>
                        <div className="large-view" style={{ "alignSelf": "center", "marginLeft": "6px" }}>{creationTime}</div>
                    </div>
                    <div className="dropdown">
                        <span className="hide" onClick={showPopUpForComment}><i className="fa fa-angle-down"></i></span>
                        {isCommentMenuVisible && <div ref={dropDownRef} className="dropdown-content">
                            {props.comment.is_editable && <div onClick={editComment}>{t("Edit")}</div>}
                            {props.comment.is_editable && <div onClick={deleteComment}>{t("Delete")}</div>}
                            {!props.comment.is_editable && <div onClick={reportComment}>{t("ReportProblem")}</div>}
                        </div>}
                    </div>
                </div>
                <div style={{ "display": "flex", "marginLeft": "10px" }}>
                    <span className="hide" onClick={replyComment}><i className="fas fa-comments"></i></span>
                    {!isUpdating &&
                        <div style={{ "marginLeft": "20px" }}
                            dangerouslySetInnerHTML={{ __html: replaceMentionsWithLinks(props.comment.content) }}>
                        </div>
                    }
                    {isUpdating && <PostCommentComponent
                        targetId={props.targetId}
                        targetType={props.targetType}
                        onReplyCommentDone={updateCommentDone}
                        commentId={props.comment.id}
                        comment={props.comment.content} />
                    }
                </div>
                {isReplying && <PostCommentComponent
                    targetId={props.targetId}
                    targetType={props.targetType}
                    replyTo={props.comment.author}
                    replyToUserId={props.comment.authorId}
                    replyType={"comment"}
                    onReplyCommentDone={replyCommentDone}
                    parentId={parentId} />}
            </li>
            {props.comment.children && <ul style={{ "marginLeft": "40px" }}>
                {childrenComments && childrenComments.map((comment, index) => (
                    <CommentComponent key={index} comment={comment} targetId={props.targetId} targetType={props.targetType} />
                ))}
            </ul>}
            {showPopup && (
                <PopupDialog
                    title={t("ReportProblem")}
                    message={checkBox}
                    onCancel={handleReportCancelClick}
                    onConfirm={handleReportConfirmClick}
                />
            )}
        </div>
    );
}));


const CommentsComponent = inject("commentsStore")(observer((props) => {
    const { t } = useTranslation();
    const [pageOffset, setPageOffset] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleScroll = () => {
        if (
            window.innerHeight + window.scrollY >= document.body.offsetHeight - 500 && !loading
        ) {
            loadMore();
        }
    };

    useEffect(() => {
        if (props.targetId) {
            setLoading(true);
            props.commentsStore.loadComments(props.targetId, props.targetType, pageOffset, 10).then(() => {
                setLoading(false);
            })
        }
    }, [props.targetId, pageOffset]);

    const Comments = observer((props) => {
        return (
            <ul>
                {props.comments.map((comment, index) => (
                    <CommentComponent key={index} comment={comment} targetId={props.targetId} targetType={props.targetType} />
                ))}
            </ul>
        );
    });

    const loadMore = () => {
        setPageOffset(pageOffset + 1);
    }

    if (!props.commentsStore.comments[props.targetId]) {
        return null;
    }
    return (
        <div style={{ "paddingTop": "20px" }}>
            <p>{t("Comments")}:</p>
            <PostCommentComponent targetId={props.targetId} parentId={props.targetId} replyType={props.targetType} targetType={props.targetType} />
            <Comments comments={props.commentsStore.comments[props.targetId]} targetId={props.targetId} targetType={props.targetType} />
        </div>
    );
}));


const PostCommentComponent = inject("authStore", "commentsStore")(observer((props) => {
    const [comment, setComment] = useState("");
    const [isEditing, setIsEditing] = useState(false);
    const [loginForm, showLogin] = useState(false);
    const [mentions, setMentions] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        setIsEditing(true);
        if (props.replyType === "comment") {
            setComment(`@[${props.replyTo}](${props.replyToUserId})`);
        } else {
            setComment(props.comment ?? "");
        }
    }, []);

    const addComment = e => {
        setIsEditing(false);
        if (props.commentId) {
            props.commentsStore.updateComment(props.targetId, props.targetType, props.commentId, comment, JSON.stringify(mentions))
                .then(() => {
                    setComment("");
                    if (props.onReplyCommentDone) {
                        props.onReplyCommentDone();
                    }
                })
                .catch((error) => {
                    if (error.status === 401) {
                        showLogin(true);
                    }
                });
        } else {
            props.commentsStore.createComment(comment, props.targetId, props.targetType, props.parentId,
                props.replyToUserId, props.replyType, mentions)
                .then(() => {
                    setComment("");
                    if (props.onReplyCommentDone) {
                        props.onReplyCommentDone();
                    }
                })
                .catch((error) => {
                    if (error.status === 401) {
                        showLogin(true);
                    }
                });
        }
    }

    const cancelComment = e => {
        setComment("");
        setIsEditing(false);
        if (props.onReplyCommentDone) {
            props.onReplyCommentDone();
        }
    }

    const getSuggestions = (query, callback) => {
        agent.Mention.Suggestions(query).then((response) => {
            if (response.users) {
                return response.users.map((user) => ({ id: user.id, display: user.userName }));
            } else {
                return [];
            }
        }).then(callback);
    }

    const onChangeHandler = (event, _newValue, _newPlainTextValue, mentions) => {
        setComment(event.target.value);
        setMentions(mentions);
    }

    return (
        <div style={{ "width": "100%", "marginBottom": "10px" }}>
            <div style={{ "display": "flex" }}>
                <p style={{ "alignSelf": "center" }}>{props.authStore.userInfo ? props.authStore.userInfo.userName : ""}</p>
                <MentionsInput
                    className="mentions"
                    classNames={classNames}
                    singleLine
                    value={comment}
                    onClick={() => { setIsEditing(true); }}
                    placeholder={t("AddComment")}
                    onChange={onChangeHandler}
                    allowSuggestionsAboveCursor={true}
                >
                    <Mention data={getSuggestions} trigger={"@"} appendSpaceOnAdd={true} />
                </MentionsInput>
            </div>
            {
                isEditing && <div className="comment-button-container">
                    <button className="comment-button" onClick={addComment}>{t("Send")}</button>
                    <button className="comment-button" onClick={cancelComment}>{t("Cancel")}</button>
                </div>
            }
            {loginForm && <LoginOverlay onClose={() => { showLogin(false) }} />}
        </div >
    );
}));

function replaceMentionsWithLinks(text) {
    const mentionsRegex = /@\[([^\]]+)\]\((\d+)\)/g;
    return text.replace(mentionsRegex, (match, display, id) => {
        return `<a href="/user_info/${display}">${display}</a>`;
    });
};

export default CommentsComponent;