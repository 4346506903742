import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { inject, observer } from "mobx-react";
import SearchBar from './SearchBar';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import LoginOverlay from '../login/LoginOverlay';

import './TopNav.css';


const TopNav = inject("authStore")(observer((props) => {
    const { t } = useTranslation();
    const [isLoggedIn, setIsLoggedIn] = useState(props.isLoggedIn);
    const [loginForm, showLogin] = useState(false);


    useEffect(() => {
        setIsLoggedIn(props.isLoggedIn);
    }, [props.isLoggedIn]);

    const handleLogout = e => {
        props.authStore.logout().then(() => window.location.reload());
    };

    const LoggedInView = (
        <div>
            <Link className='top-nav-bar large-view' to='/settings'>{t("Settings")}</Link>
            <Link className='top-nav-bar small-view' to='/settings'>
                <span className='icon'>
                    <i className="fa fa-ellipsis-h"></i>
                </span>
            </Link>
            <button className='top-nav-bar large-view' onClick={handleLogout} style={{ "marginRight": "0px" }}>{t("Logout")}</button>
            <button className='top-nav-bar small-view' onClick={handleLogout} style={{ "marginRight": "0px" }}>
                <span className='icon'>
                    <i className="fas fa-sign-out-alt"></i>
                </span>
            </button>
        </div>
    );

    const LoggedOutView = (
        <div>
            <Link className='top-nav-bar large-view' to='/login'>{t("SignIn")}</Link>
            <Link className='top-nav-bar small-view' to='/login'>
                <span className='icon'>
                    <i className="fas fa-sign-in-alt"></i>
                </span>
            </Link>
        </div>
    );

    const editorHandler = e => {
        if (!props.isLoggedIn) {
            if (!props.atLoginPage) {
                showLogin(true);
            }
        } else {
            props.history.push("/edit_post");
        }
    };

    const tooltip = <Tooltip>{t("CreatePost")}</Tooltip>;
    return (
        <div className='top-nav'>
            <div style={{ "display": "flex", "alignItems": "center" }}>
                <div className='logo-letter small-view'>{t("WebsiteShortName")}</div>
                <Link className='top-nav-bar large-view website-name' to='/'>
                    {t("WebsiteName")}
                </Link>
            </div>
            <SearchBar history={props.history} isLoggedIn={isLoggedIn} atLoginPage={props.atLoginPage} />
            <OverlayTrigger placement="bottom" overlay={tooltip}>
                <span className='create-post-icon' onClick={editorHandler}>
                    <i className="fas fa-plus"></i>
                </span>
            </OverlayTrigger>
            {loginForm && <LoginOverlay onClose={() => { showLogin(false) }} />}
            {isLoggedIn && LoggedInView}
            {!isLoggedIn && LoggedOutView}
        </div>
    );
}));

export default TopNav;