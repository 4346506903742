import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import PostDetail from "./PostDetail";

import "./PostDetail.css";


const PostDetailOverlay = inject("authStore")(observer((props) => {
    useEffect(() => {
        const handleOverlayClick = (event) => {
            // Check if the click target is the overlay itself (blank space)
            if (event.target.classList.contains('post-detail-overlay')) {
                props.onClose();
            }
        };

        // Attach the event listener when the component mounts
        document.addEventListener('click', handleOverlayClick);

        // Remove the event listener when the component unmounts
        return () => {
            document.removeEventListener('click', handleOverlayClick);
        };
    }, [props.onClose]);

    return (
        <div className="post-detail-overlay">
            <div className="post-detail-popup">
                <span className="close-button" onClick={props.onClose}><i className="fa fa-window-close"></i></span>
                <PostDetail location={props.location} />
            </div>
        </div>
    );
}));

export default PostDetailOverlay;