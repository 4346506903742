import React, { useState } from 'react';
import { observer } from "mobx-react";
import PostDetailOverlay from "./PostDetailOverlay";
import { Link } from 'react-router-dom';
import { API_STATIC } from "../shared/Constant";
import ReactPaginate from 'react-paginate';
import agent from '../agent';
import { useTranslation } from 'react-i18next';
import Masonry from 'react-masonry-css';

import "./PostList.css";


const PostList = observer((props) => {
    const [pageOffset, setPageOffset] = useState(props.pageOffset);
    const [showOverlay, setShowOverlay] = useState(false);
    const [detailPageUrl, setDetailPageUrl] = useState("");
    const { t } = useTranslation();

    if (!props.posts) {
        return null;
    }

    const showOverlayHandler = (url) => {
        setShowOverlay(true);
        setDetailPageUrl(url);
    };

    const receips = props.posts.map((post, index) => {
        return <PostItem
            key={index}
            post={post}
            draft={props.draft}
            published={props.published}
            savedPost={props.savedPost}
            history={props.history}
            onChange={props.onChange}
            showState={props.showState}
            showOverlay={showOverlayHandler}
            onCloseOverlay={() => setShowOverlay(false)}
        />;
    });

    const handlePageChange = (event) => {
        setPageOffset(event.selected);
        if (props.onPageChange) {
            props.onPageChange(event.selected)
        }
    };

    const breakpointColumnsObj = {
        default: 3,
        1000: 2,
        600: 1
    };

    const loadMore = () => {
        if (props.loadMore) {
            props.loadMore();
        }
    };

    if (props.posts.length === 0) {
        return null;
    }

    return (
        <div style={{ "display": "flex", "flexDirection": "column" }}>
            <Masonry
                breakpointCols={breakpointColumnsObj}
                className="post-container"
                columnClassName="my-masonry-grid_column"
            >
                {receips}
            </Masonry>
            {
                !props.disablePagination && <ReactPaginate
                    breakLabel="..."
                    nextLabel={`${t("Next")} >`}
                    onPageChange={handlePageChange}
                    pageRangeDisplayed={5}
                    pageCount={props.pageCount}
                    previousLabel={`< ${t("Previous")}`}
                    renderOnZeroPageCount={null}
                    containerClassName="react-paginate"
                    forcePage={pageOffset}
                    activeClassName="react-paginate-active"
                    hrefAllControls
                    pageLinkClassName="react-paginate-item"
                />
            }
            {
                props.disablePagination && <button className='load-more-button' onClick={loadMore}>{t("LoadMore")}</button>
            }
            {showOverlay && <PostDetailOverlay location={{ pathname: detailPageUrl }} onClose={() => { setShowOverlay(false) }} />}
        </div >
    );
});


const PostItem = (observer((props) => {
    const { t } = useTranslation();

    const handlePostEdit = e => {
        if (props.history) {
            props.history.push(`/update_post/${props.post.shortUrl}`)
        }
    }

    const handlePostDelete = e => {
        agent.Post.deletePost(props.post.id, 0).then(() => {
            if (props.onChange) {
                props.onChange();
            }
        }).catch();
    }

    const handlePostUnpublished = e => {
        agent.Post.deletePost(props.post.id, 2).then(() => {
            if (props.onChange) {
                props.onChange();
            }
        }).catch();
    }

    const handlePostRemoved = e => {
        // Delete post if post is already in list
        agent.Post.savePost(props.post.id).then(() => {
            if (props.onChange) {
                props.onChange();
            }
        }).catch();
    }

    const onImageClick = e => {
        if (props.showOverlay) {
            props.showOverlay(props.post.shortUrl);
        }
    }

    return (
        <div key={props.post.id} className="post-item">
            <img
                src={`${API_STATIC}${props.post.images[0]}`}
                alt={props.post.title}
                className='post-small-image'
                onClick={onImageClick}
            />
            <Link
                className="post-list-title"
                to={`/post/${props.post.shortUrl}`}
                target="_blank">{props.post.title}
            </Link>
            {!props.draft && <div className='post-liking-container'>
                <div style={{ "display": "flex" }}>
                    {props.post.authorPhoto && <img src={`${API_STATIC}${props.post.authorPhoto}`} className='user-thumbnail' alt="persona" />}
                    <Link className='truncate-text' to={`/user_info/${props.post.authorUsername}`}>{props.post.authorUsername}</Link>
                </div>
                <div style={{ "display": "flex" }}>
                    <div style={{ "display": "flex", "marginRight": "10px" }}>
                        <span style={{ "color": "#bd1f1f" }}><i className="fa fa-heart"></i></span>
                        <div style={{ "marginLeft": "10px" }}>{props.post.likings}</div>
                    </div>
                    <div style={{ "display": "flex" }}>
                        <span style={{ "color": "#bd1f1f" }}><i className="fa fa-star"></i></span>
                        <div style={{ "marginLeft": "10px" }}>{props.post.ratings}</div>
                    </div>
                </div>
                {props.showState && props.post.state && props.post.state > 2 && <div>{t(getStateLabel(props.post.state))}</div>}
            </div>}
            <div style={{ "display": "flex", "marginTop": "10px" }}>
                {props.draft && <button className='edit-button' onClick={handlePostEdit}>{t("Edit")}</button>}
                {props.published && <button className='edit-button' onClick={handlePostUnpublished}>{t("Unpublished")}</button>}
                {(props.published || props.draft) && <button className='edit-button' onClick={handlePostDelete}>{t("Delete")}</button>}
            </div>
            {props.savedPost && <button className='edit-button' onClick={handlePostRemoved}>{t("Remove")}</button>}
        </div>
    );
}));

function getStateLabel(state) {
    if (state === 8) {
        return "Published";
    } else if (state === 4) {
        return "Reviewing";
    } else {
        return ""
    }
}

export default PostList;