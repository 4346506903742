import React, { useState, useRef, useEffect } from 'react';
import { API_STATIC } from "./Constant";
import './ImageOverlay.css'; // Add your CSS file for styling

const ImageOverlay = ({ images, title }) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [isHovering, setIsHovering] = useState(false);
    const imageRef = useRef();
    const containerRef = useRef();

    const handleNextImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        setAspectRatio()
    };

    const handlePreviousImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
        setAspectRatio();
    };

    const setAspectRatio = () => {
        const image = imageRef.current;
        const imageAspectRatio = image.naturalWidth / image.naturalHeight;
        const parentElement = containerRef.current.parentNode;
        const parentWidth = parentElement.offsetWidth;
        const parentHeight = parentElement.offsetHeight;

        if (imageAspectRatio > 10 / 6) {
            containerRef.current.style.width = '100%';
            containerRef.current.style.height = `${parentWidth / imageAspectRatio}px`;
            image.style.width = '100%';
            image.style.height = `${parentWidth / imageAspectRatio}px`;

        } else {
            containerRef.current.style.width = `${parentHeight * imageAspectRatio}px`;
            containerRef.current.style.height = '100%';
            image.style.width = `${parentHeight * imageAspectRatio}px`;
            image.style.height = '100%';
        }
    };

    useEffect(() => {
        setAspectRatio();
        window.addEventListener('resize', setAspectRatio);

        return () => {
            window.removeEventListener('resize', setAspectRatio);
        };
    }, [images, currentImageIndex]);

    return (
        <div className="image-overlay-container"
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
        >
            {isHovering && <div className="prev-image" onClick={handlePreviousImage}>
                &lt;
            </div>}
            {isHovering && <div className="next-image" onClick={handleNextImage}>
                &gt;
            </div>}
            {isHovering && <div className="dot-container">
                {images.map((image, index) => (
                    <div
                        key={index}
                        className={`dot ${index === currentImageIndex ? 'active' : ''}`}
                        onClick={() => setCurrentImageIndex(index)}
                    />
                ))}
            </div>}
            <div className="image-overlay" ref={containerRef}>
                <img ref={imageRef} src={`${API_STATIC}${images[currentImageIndex]}`} alt={title} />
            </div>
        </div>
    );
};

export default ImageOverlay;
