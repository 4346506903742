import React from "react";
import TopNav from "../landingPage/TopNav";
import { useTranslation } from "react-i18next";

const NetworkErrorPage = (props) => {
    const { t } = useTranslation();

    return (
        <div className="landing-page">
            <TopNav isLoggedIn={false} history={props.history} />
            <h1>Network Error</h1>
            <p>{t("NetworkError")}</p>
        </div>
    );
};

export default NetworkErrorPage;
